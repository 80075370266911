// source: customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var basic_pb = require('./basic_pb.js');
goog.object.extend(proto, basic_pb);
goog.exportSymbol('proto.owpb.Customer', null, global);
goog.exportSymbol('proto.owpb.CustomerStatus', null, global);
goog.exportSymbol('proto.owpb.CustomerType', null, global);
goog.exportSymbol('proto.owpb.KeyType', null, global);
goog.exportSymbol('proto.owpb.License', null, global);
goog.exportSymbol('proto.owpb.PlanFeatures', null, global);
goog.exportSymbol('proto.owpb.PlanType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.Customer.displayName = 'proto.owpb.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.License = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.License, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.License.displayName = 'proto.owpb.License';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.PlanFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.PlanFeatures.repeatedFields_, null);
};
goog.inherits(proto.owpb.PlanFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.PlanFeatures.displayName = 'proto.owpb.PlanFeatures';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    crmId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxDevices: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expiryDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creationDate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    activationDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    keyId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    language: jspb.Message.getFieldWithDefault(msg, 10, ""),
    migrated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    productType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    keyType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    keyDescription: jspb.Message.getFieldWithDefault(msg, 14, ""),
    planType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    updatesGroup: jspb.Message.getFieldWithDefault(msg, 16, ""),
    dataRetention: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.Customer}
 */
proto.owpb.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.Customer;
  return proto.owpb.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.Customer}
 */
proto.owpb.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyName(value);
      break;
    case 3:
      var value = /** @type {!proto.owpb.CustomerType} */ (reader.readEnum());
      msg.setCustomerType(value);
      break;
    case 4:
      var value = /** @type {!proto.owpb.CustomerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxDevices(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExpiryDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreationDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivationDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMigrated(value);
      break;
    case 12:
      var value = /** @type {!proto.owpb.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 13:
      var value = /** @type {!proto.owpb.KeyType} */ (reader.readEnum());
      msg.setKeyType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyDescription(value);
      break;
    case 15:
      var value = /** @type {!proto.owpb.PlanType} */ (reader.readEnum());
      msg.setPlanType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatesGroup(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDataRetention(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrmId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMaxDevices();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getExpiryDate();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getActivationDate();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getKeyId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMigrated();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getKeyType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getKeyDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPlanType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getUpdatesGroup();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDataRetention();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
};


/**
 * optional string crm_id = 1;
 * @return {string}
 */
proto.owpb.Customer.prototype.getCrmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setCrmId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key_name = 2;
 * @return {string}
 */
proto.owpb.Customer.prototype.getKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setKeyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CustomerType customer_type = 3;
 * @return {!proto.owpb.CustomerType}
 */
proto.owpb.Customer.prototype.getCustomerType = function() {
  return /** @type {!proto.owpb.CustomerType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.owpb.CustomerType} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setCustomerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CustomerStatus status = 4;
 * @return {!proto.owpb.CustomerStatus}
 */
proto.owpb.Customer.prototype.getStatus = function() {
  return /** @type {!proto.owpb.CustomerStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.owpb.CustomerStatus} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 max_devices = 5;
 * @return {number}
 */
proto.owpb.Customer.prototype.getMaxDevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setMaxDevices = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 expiry_date = 6;
 * @return {number}
 */
proto.owpb.Customer.prototype.getExpiryDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setExpiryDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 creation_date = 7;
 * @return {number}
 */
proto.owpb.Customer.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 activation_date = 8;
 * @return {number}
 */
proto.owpb.Customer.prototype.getActivationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setActivationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string key_id = 9;
 * @return {string}
 */
proto.owpb.Customer.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string language = 10;
 * @return {string}
 */
proto.owpb.Customer.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool migrated = 11;
 * @return {boolean}
 */
proto.owpb.Customer.prototype.getMigrated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setMigrated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional ProductType product_type = 12;
 * @return {!proto.owpb.ProductType}
 */
proto.owpb.Customer.prototype.getProductType = function() {
  return /** @type {!proto.owpb.ProductType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.owpb.ProductType} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional KeyType key_type = 13;
 * @return {!proto.owpb.KeyType}
 */
proto.owpb.Customer.prototype.getKeyType = function() {
  return /** @type {!proto.owpb.KeyType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.owpb.KeyType} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setKeyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string key_description = 14;
 * @return {string}
 */
proto.owpb.Customer.prototype.getKeyDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setKeyDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional PlanType plan_type = 15;
 * @return {!proto.owpb.PlanType}
 */
proto.owpb.Customer.prototype.getPlanType = function() {
  return /** @type {!proto.owpb.PlanType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.owpb.PlanType} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setPlanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string updates_group = 16;
 * @return {string}
 */
proto.owpb.Customer.prototype.getUpdatesGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setUpdatesGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint32 data_retention = 17;
 * @return {number}
 */
proto.owpb.Customer.prototype.getDataRetention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.Customer} returns this
 */
proto.owpb.Customer.prototype.setDataRetention = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.License.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.License.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.License} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.License.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxDevices: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expiryDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    productType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    keyDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    planType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    planFeatures: (f = msg.getPlanFeatures()) && proto.owpb.PlanFeatures.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.License}
 */
proto.owpb.License.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.License;
  return proto.owpb.License.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.License} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.License}
 */
proto.owpb.License.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxDevices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExpiryDate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 4:
      var value = /** @type {!proto.owpb.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.owpb.PlanType} */ (reader.readEnum());
      msg.setPlanType(value);
      break;
    case 7:
      var value = new proto.owpb.PlanFeatures;
      reader.readMessage(value,proto.owpb.PlanFeatures.deserializeBinaryFromReader);
      msg.setPlanFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.License.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.License.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.License} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.License.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxDevices();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getExpiryDate();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getKeyDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlanType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPlanFeatures();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.owpb.PlanFeatures.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 max_devices = 1;
 * @return {number}
 */
proto.owpb.License.prototype.getMaxDevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setMaxDevices = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 expiry_date = 2;
 * @return {number}
 */
proto.owpb.License.prototype.getExpiryDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setExpiryDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool expired = 3;
 * @return {boolean}
 */
proto.owpb.License.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ProductType product_type = 4;
 * @return {!proto.owpb.ProductType}
 */
proto.owpb.License.prototype.getProductType = function() {
  return /** @type {!proto.owpb.ProductType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.owpb.ProductType} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string key_description = 5;
 * @return {string}
 */
proto.owpb.License.prototype.getKeyDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setKeyDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PlanType plan_type = 6;
 * @return {!proto.owpb.PlanType}
 */
proto.owpb.License.prototype.getPlanType = function() {
  return /** @type {!proto.owpb.PlanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.owpb.PlanType} value
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.setPlanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PlanFeatures plan_features = 7;
 * @return {?proto.owpb.PlanFeatures}
 */
proto.owpb.License.prototype.getPlanFeatures = function() {
  return /** @type{?proto.owpb.PlanFeatures} */ (
    jspb.Message.getWrapperField(this, proto.owpb.PlanFeatures, 7));
};


/**
 * @param {?proto.owpb.PlanFeatures|undefined} value
 * @return {!proto.owpb.License} returns this
*/
proto.owpb.License.prototype.setPlanFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.License} returns this
 */
proto.owpb.License.prototype.clearPlanFeatures = function() {
  return this.setPlanFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.License.prototype.hasPlanFeatures = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.PlanFeatures.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.PlanFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.PlanFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.PlanFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.PlanFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsExcludedList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dataRetention: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.PlanFeatures}
 */
proto.owpb.PlanFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.PlanFeatures;
  return proto.owpb.PlanFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.PlanFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.PlanFeatures}
 */
proto.owpb.PlanFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPermissionsExcluded(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDataRetention(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.PlanFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.PlanFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.PlanFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.PlanFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsExcludedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDataRetention();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated string permissions_excluded = 1;
 * @return {!Array<string>}
 */
proto.owpb.PlanFeatures.prototype.getPermissionsExcludedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.PlanFeatures} returns this
 */
proto.owpb.PlanFeatures.prototype.setPermissionsExcludedList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.PlanFeatures} returns this
 */
proto.owpb.PlanFeatures.prototype.addPermissionsExcluded = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.PlanFeatures} returns this
 */
proto.owpb.PlanFeatures.prototype.clearPermissionsExcludedList = function() {
  return this.setPermissionsExcludedList([]);
};


/**
 * optional uint32 data_retention = 2;
 * @return {number}
 */
proto.owpb.PlanFeatures.prototype.getDataRetention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.PlanFeatures} returns this
 */
proto.owpb.PlanFeatures.prototype.setDataRetention = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.owpb.CustomerStatus = {
  UNKNOWN_CUSTOMER_STATUS: 0,
  CUSTOMER_WAITING_FOR_ACTIVATION: 1,
  CUSTOMER_ACTIVE: 2,
  CUSTOMER_BLOCKED: 3
};

/**
 * @enum {number}
 */
proto.owpb.CustomerType = {
  UNKNOWN_CUSTOMER_TYPE: 0,
  PERSON: 1,
  SHOOL: 2,
  COMPANY: 3
};

/**
 * @enum {number}
 */
proto.owpb.KeyType = {
  UNKNOWN_KEY_TYPE: 0,
  STANDARD_KEY: 1,
  DEMO_KEY: 2,
  INTERNAL_KEY: 3
};

/**
 * @enum {number}
 */
proto.owpb.PlanType = {
  UNKNOWN_PLAN_TYPE: 0,
  PLAN_STANDARD: 1,
  PLAN_PLUS: 2,
  PLAN_PROFESSIONAL: 3
};

goog.object.extend(exports, proto.owpb);
