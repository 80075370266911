/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as filter_service_defs_pb from './filter_service_defs_pb';
import * as filter_service_pb from './filter_service_pb';


export class FilterServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateAppCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.AppCategory,
    (request: filter_service_pb.CreateAppCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.AppCategory.deserializeBinary
  );

  createAppCategory(
    request: filter_service_pb.CreateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.AppCategory>;

  createAppCategory(
    request: filter_service_pb.CreateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.AppCategory) => void): grpcWeb.ClientReadableStream<filter_service_pb.AppCategory>;

  createAppCategory(
    request: filter_service_pb.CreateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.AppCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateAppCategory',
        request,
        metadata || {},
        this.methodInfoCreateAppCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateAppCategory',
    request,
    metadata || {},
    this.methodInfoCreateAppCategory);
  }

  methodInfoCreateAppFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.AppFilter,
    (request: filter_service_pb.CreateAppFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.AppFilter.deserializeBinary
  );

  createAppFilter(
    request: filter_service_pb.CreateAppFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.AppFilter>;

  createAppFilter(
    request: filter_service_pb.CreateAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.AppFilter) => void): grpcWeb.ClientReadableStream<filter_service_pb.AppFilter>;

  createAppFilter(
    request: filter_service_pb.CreateAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.AppFilter) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateAppFilter',
        request,
        metadata || {},
        this.methodInfoCreateAppFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateAppFilter',
    request,
    metadata || {},
    this.methodInfoCreateAppFilter);
  }

  methodInfoCreateDesktop = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_defs_pb.Desktop,
    (request: filter_service_pb.CreateDesktopRequest) => {
      return request.serializeBinary();
    },
    filter_service_defs_pb.Desktop.deserializeBinary
  );

  createDesktop(
    request: filter_service_pb.CreateDesktopRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_defs_pb.Desktop>;

  createDesktop(
    request: filter_service_pb.CreateDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_defs_pb.Desktop) => void): grpcWeb.ClientReadableStream<filter_service_defs_pb.Desktop>;

  createDesktop(
    request: filter_service_pb.CreateDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_defs_pb.Desktop) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateDesktop',
        request,
        metadata || {},
        this.methodInfoCreateDesktop,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateDesktop',
    request,
    metadata || {},
    this.methodInfoCreateDesktop);
  }

  methodInfoCreateDesktopItem = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_defs_pb.DesktopItem,
    (request: filter_service_pb.CreateDesktopItemRequest) => {
      return request.serializeBinary();
    },
    filter_service_defs_pb.DesktopItem.deserializeBinary
  );

  createDesktopItem(
    request: filter_service_pb.CreateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_defs_pb.DesktopItem>;

  createDesktopItem(
    request: filter_service_pb.CreateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_defs_pb.DesktopItem) => void): grpcWeb.ClientReadableStream<filter_service_defs_pb.DesktopItem>;

  createDesktopItem(
    request: filter_service_pb.CreateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_defs_pb.DesktopItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateDesktopItem',
        request,
        metadata || {},
        this.methodInfoCreateDesktopItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateDesktopItem',
    request,
    metadata || {},
    this.methodInfoCreateDesktopItem);
  }

  methodInfoCreateContentFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.ContentFilter,
    (request: filter_service_pb.CreateContentFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.ContentFilter.deserializeBinary
  );

  createContentFilter(
    request: filter_service_pb.CreateContentFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.ContentFilter>;

  createContentFilter(
    request: filter_service_pb.CreateContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFilter) => void): grpcWeb.ClientReadableStream<filter_service_pb.ContentFilter>;

  createContentFilter(
    request: filter_service_pb.CreateContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFilter) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateContentFilter',
        request,
        metadata || {},
        this.methodInfoCreateContentFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateContentFilter',
    request,
    metadata || {},
    this.methodInfoCreateContentFilter);
  }

  methodInfoCreateURLCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.URLCategory,
    (request: filter_service_pb.CreateURLCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.URLCategory.deserializeBinary
  );

  createURLCategory(
    request: filter_service_pb.CreateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.URLCategory>;

  createURLCategory(
    request: filter_service_pb.CreateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategory) => void): grpcWeb.ClientReadableStream<filter_service_pb.URLCategory>;

  createURLCategory(
    request: filter_service_pb.CreateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/CreateURLCategory',
        request,
        metadata || {},
        this.methodInfoCreateURLCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/CreateURLCategory',
    request,
    metadata || {},
    this.methodInfoCreateURLCategory);
  }

  methodInfoDeleteAppFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteAppFilterResponse,
    (request: filter_service_pb.DeleteAppFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteAppFilterResponse.deserializeBinary
  );

  deleteAppFilter(
    request: filter_service_pb.DeleteAppFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteAppFilterResponse>;

  deleteAppFilter(
    request: filter_service_pb.DeleteAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteAppFilterResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteAppFilterResponse>;

  deleteAppFilter(
    request: filter_service_pb.DeleteAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteAppFilterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteAppFilter',
        request,
        metadata || {},
        this.methodInfoDeleteAppFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteAppFilter',
    request,
    metadata || {},
    this.methodInfoDeleteAppFilter);
  }

  methodInfoDeleteDesktop = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteDesktopResponse,
    (request: filter_service_pb.DeleteDesktopRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteDesktopResponse.deserializeBinary
  );

  deleteDesktop(
    request: filter_service_pb.DeleteDesktopRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteDesktopResponse>;

  deleteDesktop(
    request: filter_service_pb.DeleteDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteDesktopResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteDesktopResponse>;

  deleteDesktop(
    request: filter_service_pb.DeleteDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteDesktopResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteDesktop',
        request,
        metadata || {},
        this.methodInfoDeleteDesktop,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteDesktop',
    request,
    metadata || {},
    this.methodInfoDeleteDesktop);
  }

  methodInfoDeleteAppCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteAppCategoryResponse,
    (request: filter_service_pb.DeleteAppCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteAppCategoryResponse.deserializeBinary
  );

  deleteAppCategory(
    request: filter_service_pb.DeleteAppCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteAppCategoryResponse>;

  deleteAppCategory(
    request: filter_service_pb.DeleteAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteAppCategoryResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteAppCategoryResponse>;

  deleteAppCategory(
    request: filter_service_pb.DeleteAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteAppCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteAppCategory',
        request,
        metadata || {},
        this.methodInfoDeleteAppCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteAppCategory',
    request,
    metadata || {},
    this.methodInfoDeleteAppCategory);
  }

  methodInfoDeleteDesktopItem = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteDesktopItemResponse,
    (request: filter_service_pb.DeleteDesktopItemRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteDesktopItemResponse.deserializeBinary
  );

  deleteDesktopItem(
    request: filter_service_pb.DeleteDesktopItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteDesktopItemResponse>;

  deleteDesktopItem(
    request: filter_service_pb.DeleteDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteDesktopItemResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteDesktopItemResponse>;

  deleteDesktopItem(
    request: filter_service_pb.DeleteDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteDesktopItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteDesktopItem',
        request,
        metadata || {},
        this.methodInfoDeleteDesktopItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteDesktopItem',
    request,
    metadata || {},
    this.methodInfoDeleteDesktopItem);
  }

  methodInfoDeleteURLCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteURLCategoryResponse,
    (request: filter_service_pb.DeleteURLCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteURLCategoryResponse.deserializeBinary
  );

  deleteURLCategory(
    request: filter_service_pb.DeleteURLCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteURLCategoryResponse>;

  deleteURLCategory(
    request: filter_service_pb.DeleteURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteURLCategoryResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteURLCategoryResponse>;

  deleteURLCategory(
    request: filter_service_pb.DeleteURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteURLCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteURLCategory',
        request,
        metadata || {},
        this.methodInfoDeleteURLCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteURLCategory',
    request,
    metadata || {},
    this.methodInfoDeleteURLCategory);
  }

  methodInfoDeleteContentFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.DeleteContentFilterResponse,
    (request: filter_service_pb.DeleteContentFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.DeleteContentFilterResponse.deserializeBinary
  );

  deleteContentFilter(
    request: filter_service_pb.DeleteContentFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.DeleteContentFilterResponse>;

  deleteContentFilter(
    request: filter_service_pb.DeleteContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteContentFilterResponse) => void): grpcWeb.ClientReadableStream<filter_service_pb.DeleteContentFilterResponse>;

  deleteContentFilter(
    request: filter_service_pb.DeleteContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.DeleteContentFilterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/DeleteContentFilter',
        request,
        metadata || {},
        this.methodInfoDeleteContentFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/DeleteContentFilter',
    request,
    metadata || {},
    this.methodInfoDeleteContentFilter);
  }

  methodInfoGetContentFilters = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.ContentFiltersCollection,
    (request: filter_service_pb.GetContentFiltersRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.ContentFiltersCollection.deserializeBinary
  );

  getContentFilters(
    request: filter_service_pb.GetContentFiltersRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.ContentFiltersCollection>;

  getContentFilters(
    request: filter_service_pb.GetContentFiltersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFiltersCollection) => void): grpcWeb.ClientReadableStream<filter_service_pb.ContentFiltersCollection>;

  getContentFilters(
    request: filter_service_pb.GetContentFiltersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFiltersCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/GetContentFilters',
        request,
        metadata || {},
        this.methodInfoGetContentFilters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/GetContentFilters',
    request,
    metadata || {},
    this.methodInfoGetContentFilters);
  }

  methodInfoGetURLCategories = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.URLCategoriesCollection,
    (request: filter_service_pb.GetURLCategoriesRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.URLCategoriesCollection.deserializeBinary
  );

  getURLCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.URLCategoriesCollection>;

  getURLCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategoriesCollection) => void): grpcWeb.ClientReadableStream<filter_service_pb.URLCategoriesCollection>;

  getURLCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategoriesCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/GetURLCategories',
        request,
        metadata || {},
        this.methodInfoGetURLCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/GetURLCategories',
    request,
    metadata || {},
    this.methodInfoGetURLCategories);
  }

  methodInfoGetURLSystemCategories = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.URLCategoriesCollection,
    (request: filter_service_pb.GetURLCategoriesRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.URLCategoriesCollection.deserializeBinary
  );

  getURLSystemCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.URLCategoriesCollection>;

  getURLSystemCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategoriesCollection) => void): grpcWeb.ClientReadableStream<filter_service_pb.URLCategoriesCollection>;

  getURLSystemCategories(
    request: filter_service_pb.GetURLCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategoriesCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/GetURLSystemCategories',
        request,
        metadata || {},
        this.methodInfoGetURLSystemCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/GetURLSystemCategories',
    request,
    metadata || {},
    this.methodInfoGetURLSystemCategories);
  }

  methodInfoUpdateAppCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.AppCategory,
    (request: filter_service_pb.UpdateAppCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.AppCategory.deserializeBinary
  );

  updateAppCategory(
    request: filter_service_pb.UpdateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.AppCategory>;

  updateAppCategory(
    request: filter_service_pb.UpdateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.AppCategory) => void): grpcWeb.ClientReadableStream<filter_service_pb.AppCategory>;

  updateAppCategory(
    request: filter_service_pb.UpdateAppCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.AppCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateAppCategory',
        request,
        metadata || {},
        this.methodInfoUpdateAppCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateAppCategory',
    request,
    metadata || {},
    this.methodInfoUpdateAppCategory);
  }

  methodInfoUpdateAppFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.AppFilter,
    (request: filter_service_pb.UpdateAppFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.AppFilter.deserializeBinary
  );

  updateAppFilter(
    request: filter_service_pb.UpdateAppFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.AppFilter>;

  updateAppFilter(
    request: filter_service_pb.UpdateAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.AppFilter) => void): grpcWeb.ClientReadableStream<filter_service_pb.AppFilter>;

  updateAppFilter(
    request: filter_service_pb.UpdateAppFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.AppFilter) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateAppFilter',
        request,
        metadata || {},
        this.methodInfoUpdateAppFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateAppFilter',
    request,
    metadata || {},
    this.methodInfoUpdateAppFilter);
  }

  methodInfoUpdateDesktop = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_defs_pb.Desktop,
    (request: filter_service_pb.UpdateDesktopRequest) => {
      return request.serializeBinary();
    },
    filter_service_defs_pb.Desktop.deserializeBinary
  );

  updateDesktop(
    request: filter_service_pb.UpdateDesktopRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_defs_pb.Desktop>;

  updateDesktop(
    request: filter_service_pb.UpdateDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_defs_pb.Desktop) => void): grpcWeb.ClientReadableStream<filter_service_defs_pb.Desktop>;

  updateDesktop(
    request: filter_service_pb.UpdateDesktopRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_defs_pb.Desktop) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateDesktop',
        request,
        metadata || {},
        this.methodInfoUpdateDesktop,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateDesktop',
    request,
    metadata || {},
    this.methodInfoUpdateDesktop);
  }

  methodInfoUpdateDesktopItem = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_defs_pb.DesktopItem,
    (request: filter_service_pb.UpdateDesktopItemRequest) => {
      return request.serializeBinary();
    },
    filter_service_defs_pb.DesktopItem.deserializeBinary
  );

  updateDesktopItem(
    request: filter_service_pb.UpdateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_defs_pb.DesktopItem>;

  updateDesktopItem(
    request: filter_service_pb.UpdateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_defs_pb.DesktopItem) => void): grpcWeb.ClientReadableStream<filter_service_defs_pb.DesktopItem>;

  updateDesktopItem(
    request: filter_service_pb.UpdateDesktopItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_defs_pb.DesktopItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateDesktopItem',
        request,
        metadata || {},
        this.methodInfoUpdateDesktopItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateDesktopItem',
    request,
    metadata || {},
    this.methodInfoUpdateDesktopItem);
  }

  methodInfoUpdateContentFilter = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.ContentFilter,
    (request: filter_service_pb.UpdateContentFilterRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.ContentFilter.deserializeBinary
  );

  updateContentFilter(
    request: filter_service_pb.UpdateContentFilterRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.ContentFilter>;

  updateContentFilter(
    request: filter_service_pb.UpdateContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFilter) => void): grpcWeb.ClientReadableStream<filter_service_pb.ContentFilter>;

  updateContentFilter(
    request: filter_service_pb.UpdateContentFilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.ContentFilter) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateContentFilter',
        request,
        metadata || {},
        this.methodInfoUpdateContentFilter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateContentFilter',
    request,
    metadata || {},
    this.methodInfoUpdateContentFilter);
  }

  methodInfoUpdateURLCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    filter_service_pb.URLCategory,
    (request: filter_service_pb.UpdateURLCategoryRequest) => {
      return request.serializeBinary();
    },
    filter_service_pb.URLCategory.deserializeBinary
  );

  updateURLCategory(
    request: filter_service_pb.UpdateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<filter_service_pb.URLCategory>;

  updateURLCategory(
    request: filter_service_pb.UpdateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategory) => void): grpcWeb.ClientReadableStream<filter_service_pb.URLCategory>;

  updateURLCategory(
    request: filter_service_pb.UpdateURLCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: filter_service_pb.URLCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.FilterService/UpdateURLCategory',
        request,
        metadata || {},
        this.methodInfoUpdateURLCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.FilterService/UpdateURLCategory',
    request,
    metadata || {},
    this.methodInfoUpdateURLCategory);
  }

}

