/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as pushsub_service_pb from './pushsub_service_pb';


export class PushSubServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoInitEventsStream = new grpcWeb.AbstractClientBase.MethodInfo(
    pushsub_service_pb.EventMessage,
    (request: pushsub_service_pb.SubscribeRequest) => {
      return request.serializeBinary();
    },
    pushsub_service_pb.EventMessage.deserializeBinary
  );

  initEventsStream(
    request: pushsub_service_pb.SubscribeRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/owpb.PushSubService/InitEventsStream',
      request,
      metadata || {},
      this.methodInfoInitEventsStream);
  }

  methodInfoDistribute = new grpcWeb.AbstractClientBase.MethodInfo(
    pushsub_service_pb.DistributeResponse,
    (request: pushsub_service_pb.DistributeRequest) => {
      return request.serializeBinary();
    },
    pushsub_service_pb.DistributeResponse.deserializeBinary
  );

  distribute(
    request: pushsub_service_pb.DistributeRequest,
    metadata: grpcWeb.Metadata | null): Promise<pushsub_service_pb.DistributeResponse>;

  distribute(
    request: pushsub_service_pb.DistributeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: pushsub_service_pb.DistributeResponse) => void): grpcWeb.ClientReadableStream<pushsub_service_pb.DistributeResponse>;

  distribute(
    request: pushsub_service_pb.DistributeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: pushsub_service_pb.DistributeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.PushSubService/Distribute',
        request,
        metadata || {},
        this.methodInfoDistribute,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.PushSubService/Distribute',
    request,
    metadata || {},
    this.methodInfoDistribute);
  }

  methodInfoSubscribe = new grpcWeb.AbstractClientBase.MethodInfo(
    pushsub_service_pb.SubscribeResponse,
    (request: pushsub_service_pb.SubscribeRequest) => {
      return request.serializeBinary();
    },
    pushsub_service_pb.SubscribeResponse.deserializeBinary
  );

  subscribe(
    request: pushsub_service_pb.SubscribeRequest,
    metadata: grpcWeb.Metadata | null): Promise<pushsub_service_pb.SubscribeResponse>;

  subscribe(
    request: pushsub_service_pb.SubscribeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: pushsub_service_pb.SubscribeResponse) => void): grpcWeb.ClientReadableStream<pushsub_service_pb.SubscribeResponse>;

  subscribe(
    request: pushsub_service_pb.SubscribeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: pushsub_service_pb.SubscribeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.PushSubService/Subscribe',
        request,
        metadata || {},
        this.methodInfoSubscribe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.PushSubService/Subscribe',
    request,
    metadata || {},
    this.methodInfoSubscribe);
  }

  methodInfoPublish = new grpcWeb.AbstractClientBase.MethodInfo(
    pushsub_service_pb.PublishResponse,
    (request: pushsub_service_pb.PublishRequest) => {
      return request.serializeBinary();
    },
    pushsub_service_pb.PublishResponse.deserializeBinary
  );

  publish(
    request: pushsub_service_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null): Promise<pushsub_service_pb.PublishResponse>;

  publish(
    request: pushsub_service_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: pushsub_service_pb.PublishResponse) => void): grpcWeb.ClientReadableStream<pushsub_service_pb.PublishResponse>;

  publish(
    request: pushsub_service_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: pushsub_service_pb.PublishResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.PushSubService/Publish',
        request,
        metadata || {},
        this.methodInfoPublish,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.PushSubService/Publish',
    request,
    metadata || {},
    this.methodInfoPublish);
  }

}

