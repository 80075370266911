import React from 'react';
import { Button, Link, Menu } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
/*
import { NavLink } from 'react-router-dom';
*/
import { scrollbarStyles } from '@/utils/styles';
import { logout } from '@/api';
import { downloadFilesPageUrl, helpPageUrl, termsAndServiceUrl } from '@/constants/common';
/*  Ukrycie menu planu
import arrowUp from '@/assets/arrowUp.png';
import { urlStructure } from '@/constants/urlStructure';
*/


const StyledMenu = styled(Menu)`
  border-radius: 0;
  margin-top: 30px;

  ul {
    padding-bottom: 0;
    width: 250px;
  }

  & > .MuiPaper-root {
    ${scrollbarStyles}
  }
`;

const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const StyledMenuItem = styled(Button)<{ $highlighted?: boolean, component?: React.ReactNode, to?: string }>`
  width: 100%;
  text-transform: none;
  text-decoration: none;
  justify-content: flex-start;
  padding-left: 15px;
  color: ${({ theme, $highlighted }) => (!$highlighted ? theme.palette.common.black : theme.palette.primary.main)};
`;

const MenuSection = styled.div`
  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.thickBorder};
  }
`;

/* Ukrycie menu planu
const MenuIcon = styled.img`
  padding-right: 5px;
  margin-bottom: -1px;
`;
*/

interface UserContextMenuProps {
  anchorEl: null | HTMLElement,
  handleClose: () => void,
}

const UserContextMenu = ({ anchorEl, handleClose }: UserContextMenuProps) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledMenu
      elevation={2}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      <MenuSection>
        {/* Ukrycie menu profilu
        <StyledMenuItem
          onClick={handleClose}
          component={NavLink}
          to={urlStructure.userprofile}
        >
          {t('common:app.nav.myProfile')}
        </StyledMenuItem>
        */}
        <StyledMenuItem onClick={handleClose}>
          <StyledLink
            color="textPrimary"
            href={termsAndServiceUrl}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t('common:app.nav.regulations')}
          </StyledLink>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <StyledLink
            color="textPrimary"
            href={helpPageUrl}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t('common:app.nav.help')}
          </StyledLink>
        </StyledMenuItem>
        {/*
        <StyledMenuItem
          onClick={handleClose}
          component={NavLink}
          to={urlStructure.filesForDownload}
        >
          {t('common:app.nav.filesForDownload')}
        </StyledMenuItem>
        */}
        <StyledMenuItem onClick={handleClose}>
          <StyledLink
            color="textPrimary"
            href={downloadFilesPageUrl}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t('common:app.nav.filesForDownload')}
          </StyledLink>
        </StyledMenuItem>

        <StyledMenuItem onClick={logout}>
          {t('common:app.nav.logout')}
        </StyledMenuItem>
      </MenuSection>
      {/* Ukrywamy menu planu
      <MenuSection>
        <StyledMenuItem
          onClick={handleClose}
          component={NavLink}
          $highlighted
          to={urlStructure.planConfiguration}
        >
          <MenuIcon alt="" src={arrowUp} />
          {t('common:app.nav.upgradePlan')}
        </StyledMenuItem>
      </MenuSection>
      */}
    </StyledMenu>
  );
};

export default UserContextMenu;
