/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as subscription_service_pb from './subscription_service_pb';


export class SubscriptionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCancelSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.CancelSubscriptionResponse,
    (request: subscription_service_pb.CancelSubscriptionRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.CancelSubscriptionResponse.deserializeBinary
  );

  cancelSubscription(
    request: subscription_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.CancelSubscriptionResponse>;

  cancelSubscription(
    request: subscription_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.CancelSubscriptionResponse) => void): grpcWeb.ClientReadableStream<subscription_service_pb.CancelSubscriptionResponse>;

  cancelSubscription(
    request: subscription_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.CancelSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/CancelSubscription',
        request,
        metadata || {},
        this.methodInfoCancelSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/CancelSubscription',
    request,
    metadata || {},
    this.methodInfoCancelSubscription);
  }

  methodInfoChangePaymentSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.ChangePaymentSettingsResponse,
    (request: subscription_service_pb.ChangePaymentSettingsRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.ChangePaymentSettingsResponse.deserializeBinary
  );

  changePaymentSettings(
    request: subscription_service_pb.ChangePaymentSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.ChangePaymentSettingsResponse>;

  changePaymentSettings(
    request: subscription_service_pb.ChangePaymentSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.ChangePaymentSettingsResponse) => void): grpcWeb.ClientReadableStream<subscription_service_pb.ChangePaymentSettingsResponse>;

  changePaymentSettings(
    request: subscription_service_pb.ChangePaymentSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.ChangePaymentSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/ChangePaymentSettings',
        request,
        metadata || {},
        this.methodInfoChangePaymentSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/ChangePaymentSettings',
    request,
    metadata || {},
    this.methodInfoChangePaymentSettings);
  }

  methodInfoGetPlans = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.PlansCollection,
    (request: subscription_service_pb.GetPlansCollectionRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.PlansCollection.deserializeBinary
  );

  getPlans(
    request: subscription_service_pb.GetPlansCollectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.PlansCollection>;

  getPlans(
    request: subscription_service_pb.GetPlansCollectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.PlansCollection) => void): grpcWeb.ClientReadableStream<subscription_service_pb.PlansCollection>;

  getPlans(
    request: subscription_service_pb.GetPlansCollectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.PlansCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/GetPlans',
        request,
        metadata || {},
        this.methodInfoGetPlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/GetPlans',
    request,
    metadata || {},
    this.methodInfoGetPlans);
  }

  methodInfoGetSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.Subscription,
    (request: subscription_service_pb.GetSubscriptionRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.Subscription.deserializeBinary
  );

  getSubscription(
    request: subscription_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.Subscription>;

  getSubscription(
    request: subscription_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.Subscription) => void): grpcWeb.ClientReadableStream<subscription_service_pb.Subscription>;

  getSubscription(
    request: subscription_service_pb.GetSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.Subscription) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/GetSubscription',
        request,
        metadata || {},
        this.methodInfoGetSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/GetSubscription',
    request,
    metadata || {},
    this.methodInfoGetSubscription);
  }

  methodInfoGetUpcomingInvoice = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.UpcomingInvoice,
    (request: subscription_service_pb.GetUpcomingInvoiceRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.UpcomingInvoice.deserializeBinary
  );

  getUpcomingInvoice(
    request: subscription_service_pb.GetUpcomingInvoiceRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.UpcomingInvoice>;

  getUpcomingInvoice(
    request: subscription_service_pb.GetUpcomingInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.UpcomingInvoice) => void): grpcWeb.ClientReadableStream<subscription_service_pb.UpcomingInvoice>;

  getUpcomingInvoice(
    request: subscription_service_pb.GetUpcomingInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.UpcomingInvoice) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/GetUpcomingInvoice',
        request,
        metadata || {},
        this.methodInfoGetUpcomingInvoice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/GetUpcomingInvoice',
    request,
    metadata || {},
    this.methodInfoGetUpcomingInvoice);
  }

  methodInfoUpdateSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    subscription_service_pb.UpdateSubscriptionResponse,
    (request: subscription_service_pb.UpdateSubscriptionRequest) => {
      return request.serializeBinary();
    },
    subscription_service_pb.UpdateSubscriptionResponse.deserializeBinary
  );

  updateSubscription(
    request: subscription_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<subscription_service_pb.UpdateSubscriptionResponse>;

  updateSubscription(
    request: subscription_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: subscription_service_pb.UpdateSubscriptionResponse) => void): grpcWeb.ClientReadableStream<subscription_service_pb.UpdateSubscriptionResponse>;

  updateSubscription(
    request: subscription_service_pb.UpdateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: subscription_service_pb.UpdateSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SubscriptionService/UpdateSubscription',
        request,
        metadata || {},
        this.methodInfoUpdateSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SubscriptionService/UpdateSubscription',
    request,
    metadata || {},
    this.methodInfoUpdateSubscription);
  }

}

