/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as history_service_pb from './history_service_pb';


export class HistoryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoDeleteAppHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.DeleteAppHistoryResponse,
    (request: history_service_pb.DeleteAppHistoryRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.DeleteAppHistoryResponse.deserializeBinary
  );

  deleteAppHistory(
    request: history_service_pb.DeleteAppHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.DeleteAppHistoryResponse>;

  deleteAppHistory(
    request: history_service_pb.DeleteAppHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.DeleteAppHistoryResponse) => void): grpcWeb.ClientReadableStream<history_service_pb.DeleteAppHistoryResponse>;

  deleteAppHistory(
    request: history_service_pb.DeleteAppHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.DeleteAppHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/DeleteAppHistory',
        request,
        metadata || {},
        this.methodInfoDeleteAppHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/DeleteAppHistory',
    request,
    metadata || {},
    this.methodInfoDeleteAppHistory);
  }

  methodInfoDeleteWebHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.DeleteWebHistoryResponse,
    (request: history_service_pb.DeleteWebHistoryRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.DeleteWebHistoryResponse.deserializeBinary
  );

  deleteWebHistory(
    request: history_service_pb.DeleteWebHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.DeleteWebHistoryResponse>;

  deleteWebHistory(
    request: history_service_pb.DeleteWebHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.DeleteWebHistoryResponse) => void): grpcWeb.ClientReadableStream<history_service_pb.DeleteWebHistoryResponse>;

  deleteWebHistory(
    request: history_service_pb.DeleteWebHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.DeleteWebHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/DeleteWebHistory',
        request,
        metadata || {},
        this.methodInfoDeleteWebHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/DeleteWebHistory',
    request,
    metadata || {},
    this.methodInfoDeleteWebHistory);
  }

  methodInfoGetAppHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.AppHistoryCollection,
    (request: history_service_pb.GetAppHistoryRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.AppHistoryCollection.deserializeBinary
  );

  getAppHistory(
    request: history_service_pb.GetAppHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.AppHistoryCollection>;

  getAppHistory(
    request: history_service_pb.GetAppHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.AppHistoryCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.AppHistoryCollection>;

  getAppHistory(
    request: history_service_pb.GetAppHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.AppHistoryCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetAppHistory',
        request,
        metadata || {},
        this.methodInfoGetAppHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetAppHistory',
    request,
    metadata || {},
    this.methodInfoGetAppHistory);
  }

  methodInfoGetAppHistoryOldestTimestamp = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.HistoryOldestTimestampResponse,
    (request: history_service_pb.GetAppHistoryOldestTimestampRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.HistoryOldestTimestampResponse.deserializeBinary
  );

  getAppHistoryOldestTimestamp(
    request: history_service_pb.GetAppHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.HistoryOldestTimestampResponse>;

  getAppHistoryOldestTimestamp(
    request: history_service_pb.GetAppHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.HistoryOldestTimestampResponse) => void): grpcWeb.ClientReadableStream<history_service_pb.HistoryOldestTimestampResponse>;

  getAppHistoryOldestTimestamp(
    request: history_service_pb.GetAppHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.HistoryOldestTimestampResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetAppHistoryOldestTimestamp',
        request,
        metadata || {},
        this.methodInfoGetAppHistoryOldestTimestamp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetAppHistoryOldestTimestamp',
    request,
    metadata || {},
    this.methodInfoGetAppHistoryOldestTimestamp);
  }

  methodInfoGetAppHistoryUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.DomainAndUserCollection,
    (request: history_service_pb.GetAppHistoryUsersRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.DomainAndUserCollection.deserializeBinary
  );

  getAppHistoryUsers(
    request: history_service_pb.GetAppHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.DomainAndUserCollection>;

  getAppHistoryUsers(
    request: history_service_pb.GetAppHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.DomainAndUserCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.DomainAndUserCollection>;

  getAppHistoryUsers(
    request: history_service_pb.GetAppHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.DomainAndUserCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetAppHistoryUsers',
        request,
        metadata || {},
        this.methodInfoGetAppHistoryUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetAppHistoryUsers',
    request,
    metadata || {},
    this.methodInfoGetAppHistoryUsers);
  }

  methodInfoGetAppHistoryStats = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.AppHistoryStatsCollection,
    (request: history_service_pb.GetAppHistoryStatsRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.AppHistoryStatsCollection.deserializeBinary
  );

  getAppHistoryStats(
    request: history_service_pb.GetAppHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.AppHistoryStatsCollection>;

  getAppHistoryStats(
    request: history_service_pb.GetAppHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.AppHistoryStatsCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.AppHistoryStatsCollection>;

  getAppHistoryStats(
    request: history_service_pb.GetAppHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.AppHistoryStatsCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetAppHistoryStats',
        request,
        metadata || {},
        this.methodInfoGetAppHistoryStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetAppHistoryStats',
    request,
    metadata || {},
    this.methodInfoGetAppHistoryStats);
  }

  methodInfoGetUrlDomainHistoryStats = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.UrlDomainHistoryStatsCollection,
    (request: history_service_pb.GetUrlDomainHistoryStatsRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.UrlDomainHistoryStatsCollection.deserializeBinary
  );

  getUrlDomainHistoryStats(
    request: history_service_pb.GetUrlDomainHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.UrlDomainHistoryStatsCollection>;

  getUrlDomainHistoryStats(
    request: history_service_pb.GetUrlDomainHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.UrlDomainHistoryStatsCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.UrlDomainHistoryStatsCollection>;

  getUrlDomainHistoryStats(
    request: history_service_pb.GetUrlDomainHistoryStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.UrlDomainHistoryStatsCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetUrlDomainHistoryStats',
        request,
        metadata || {},
        this.methodInfoGetUrlDomainHistoryStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetUrlDomainHistoryStats',
    request,
    metadata || {},
    this.methodInfoGetUrlDomainHistoryStats);
  }

  methodInfoGetWebHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.WebHistoryCollection,
    (request: history_service_pb.GetWebHistoryRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.WebHistoryCollection.deserializeBinary
  );

  getWebHistory(
    request: history_service_pb.GetWebHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.WebHistoryCollection>;

  getWebHistory(
    request: history_service_pb.GetWebHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.WebHistoryCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.WebHistoryCollection>;

  getWebHistory(
    request: history_service_pb.GetWebHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.WebHistoryCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetWebHistory',
        request,
        metadata || {},
        this.methodInfoGetWebHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetWebHistory',
    request,
    metadata || {},
    this.methodInfoGetWebHistory);
  }

  methodInfoGetWebHistoryOldestTimestamp = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.HistoryOldestTimestampResponse,
    (request: history_service_pb.GetWebHistoryOldestTimestampRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.HistoryOldestTimestampResponse.deserializeBinary
  );

  getWebHistoryOldestTimestamp(
    request: history_service_pb.GetWebHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.HistoryOldestTimestampResponse>;

  getWebHistoryOldestTimestamp(
    request: history_service_pb.GetWebHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.HistoryOldestTimestampResponse) => void): grpcWeb.ClientReadableStream<history_service_pb.HistoryOldestTimestampResponse>;

  getWebHistoryOldestTimestamp(
    request: history_service_pb.GetWebHistoryOldestTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.HistoryOldestTimestampResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetWebHistoryOldestTimestamp',
        request,
        metadata || {},
        this.methodInfoGetWebHistoryOldestTimestamp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetWebHistoryOldestTimestamp',
    request,
    metadata || {},
    this.methodInfoGetWebHistoryOldestTimestamp);
  }

  methodInfoGetWebHistoryUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    history_service_pb.DomainAndUserCollection,
    (request: history_service_pb.GetWebHistoryUsersRequest) => {
      return request.serializeBinary();
    },
    history_service_pb.DomainAndUserCollection.deserializeBinary
  );

  getWebHistoryUsers(
    request: history_service_pb.GetWebHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<history_service_pb.DomainAndUserCollection>;

  getWebHistoryUsers(
    request: history_service_pb.GetWebHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: history_service_pb.DomainAndUserCollection) => void): grpcWeb.ClientReadableStream<history_service_pb.DomainAndUserCollection>;

  getWebHistoryUsers(
    request: history_service_pb.GetWebHistoryUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: history_service_pb.DomainAndUserCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.HistoryService/GetWebHistoryUsers',
        request,
        metadata || {},
        this.methodInfoGetWebHistoryUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.HistoryService/GetWebHistoryUsers',
    request,
    metadata || {},
    this.methodInfoGetWebHistoryUsers);
  }

}

