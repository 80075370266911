// source: basic.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.owpb.ApiError', null, global);
goog.exportSymbol('proto.owpb.ApiErrorType', null, global);
goog.exportSymbol('proto.owpb.AppControlState', null, global);
goog.exportSymbol('proto.owpb.AppHistoryItemType', null, global);
goog.exportSymbol('proto.owpb.AppHistoryLogFlags', null, global);
goog.exportSymbol('proto.owpb.AppType', null, global);
goog.exportSymbol('proto.owpb.AuthTokenPermissionFlags', null, global);
goog.exportSymbol('proto.owpb.ControlState', null, global);
goog.exportSymbol('proto.owpb.Country', null, global);
goog.exportSymbol('proto.owpb.DeviceGroupsSelectMode', null, global);
goog.exportSymbol('proto.owpb.DeviceSessionType', null, global);
goog.exportSymbol('proto.owpb.DeviceType', null, global);
goog.exportSymbol('proto.owpb.InetControlState', null, global);
goog.exportSymbol('proto.owpb.Language', null, global);
goog.exportSymbol('proto.owpb.MessageWndToShow', null, global);
goog.exportSymbol('proto.owpb.NetworkInterfaceInfo', null, global);
goog.exportSymbol('proto.owpb.NetworkInterfaceInfoV6', null, global);
goog.exportSymbol('proto.owpb.ProductType', null, global);
goog.exportSymbol('proto.owpb.SearchEnginesFlags', null, global);
goog.exportSymbol('proto.owpb.SystemI18nInfo', null, global);
goog.exportSymbol('proto.owpb.UserAuthType', null, global);
goog.exportSymbol('proto.owpb.UserStatus', null, global);
goog.exportSymbol('proto.owpb.WebHistoryLogFlags', null, global);
goog.exportSymbol('proto.owpb.WindowsUserInfo', null, global);
goog.exportSymbol('proto.owpb.WindowsUserSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.ApiError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.ApiError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.ApiError.displayName = 'proto.owpb.ApiError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.MessageWndToShow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.MessageWndToShow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.MessageWndToShow.displayName = 'proto.owpb.MessageWndToShow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.NetworkInterfaceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.NetworkInterfaceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.NetworkInterfaceInfo.displayName = 'proto.owpb.NetworkInterfaceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.NetworkInterfaceInfoV6 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.NetworkInterfaceInfoV6, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.NetworkInterfaceInfoV6.displayName = 'proto.owpb.NetworkInterfaceInfoV6';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.WindowsUserSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.WindowsUserSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.WindowsUserSettings.displayName = 'proto.owpb.WindowsUserSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.WindowsUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.WindowsUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.WindowsUserInfo.displayName = 'proto.owpb.WindowsUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.Country.displayName = 'proto.owpb.Country';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.Language = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.Language, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.Language.displayName = 'proto.owpb.Language';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.SystemI18nInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.SystemI18nInfo.repeatedFields_, null);
};
goog.inherits(proto.owpb.SystemI18nInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.SystemI18nInfo.displayName = 'proto.owpb.SystemI18nInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.ApiError.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.ApiError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.ApiError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ApiError.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageToShow: (f = msg.getMessageToShow()) && proto.owpb.MessageWndToShow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.ApiError}
 */
proto.owpb.ApiError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.ApiError;
  return proto.owpb.ApiError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.ApiError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.ApiError}
 */
proto.owpb.ApiError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.owpb.ApiErrorType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    case 2:
      var value = new proto.owpb.MessageWndToShow;
      reader.readMessage(value,proto.owpb.MessageWndToShow.deserializeBinaryFromReader);
      msg.setMessageToShow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.ApiError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.ApiError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.ApiError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ApiError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessageToShow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.owpb.MessageWndToShow.serializeBinaryToWriter
    );
  }
};


/**
 * optional ApiErrorType error_type = 1;
 * @return {!proto.owpb.ApiErrorType}
 */
proto.owpb.ApiError.prototype.getErrorType = function() {
  return /** @type {!proto.owpb.ApiErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.owpb.ApiErrorType} value
 * @return {!proto.owpb.ApiError} returns this
 */
proto.owpb.ApiError.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MessageWndToShow message_to_show = 2;
 * @return {?proto.owpb.MessageWndToShow}
 */
proto.owpb.ApiError.prototype.getMessageToShow = function() {
  return /** @type{?proto.owpb.MessageWndToShow} */ (
    jspb.Message.getWrapperField(this, proto.owpb.MessageWndToShow, 2));
};


/**
 * @param {?proto.owpb.MessageWndToShow|undefined} value
 * @return {!proto.owpb.ApiError} returns this
*/
proto.owpb.ApiError.prototype.setMessageToShow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.ApiError} returns this
 */
proto.owpb.ApiError.prototype.clearMessageToShow = function() {
  return this.setMessageToShow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.ApiError.prototype.hasMessageToShow = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.MessageWndToShow.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.MessageWndToShow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.MessageWndToShow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.MessageWndToShow.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skipErrorMsg: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    mustConfirm: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    closeApp: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    maximize: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    width: jspb.Message.getFieldWithDefault(msg, 7, 0),
    height: jspb.Message.getFieldWithDefault(msg, 8, 0),
    minShowTime: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.MessageWndToShow}
 */
proto.owpb.MessageWndToShow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.MessageWndToShow;
  return proto.owpb.MessageWndToShow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.MessageWndToShow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.MessageWndToShow}
 */
proto.owpb.MessageWndToShow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipErrorMsg(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMustConfirm(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCloseApp(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaximize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinShowTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.MessageWndToShow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.MessageWndToShow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.MessageWndToShow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.MessageWndToShow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkipErrorMsg();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMustConfirm();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCloseApp();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMaximize();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getMinShowTime();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.owpb.MessageWndToShow.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.owpb.MessageWndToShow.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool skip_error_msg = 3;
 * @return {boolean}
 */
proto.owpb.MessageWndToShow.prototype.getSkipErrorMsg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setSkipErrorMsg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool must_confirm = 4;
 * @return {boolean}
 */
proto.owpb.MessageWndToShow.prototype.getMustConfirm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setMustConfirm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool close_app = 5;
 * @return {boolean}
 */
proto.owpb.MessageWndToShow.prototype.getCloseApp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setCloseApp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool maximize = 6;
 * @return {boolean}
 */
proto.owpb.MessageWndToShow.prototype.getMaximize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setMaximize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 width = 7;
 * @return {number}
 */
proto.owpb.MessageWndToShow.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 height = 8;
 * @return {number}
 */
proto.owpb.MessageWndToShow.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 min_show_time = 9;
 * @return {number}
 */
proto.owpb.MessageWndToShow.prototype.getMinShowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.MessageWndToShow} returns this
 */
proto.owpb.MessageWndToShow.prototype.setMinShowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.NetworkInterfaceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.NetworkInterfaceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.NetworkInterfaceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.NetworkInterfaceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mac: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    haveGateway: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.NetworkInterfaceInfo}
 */
proto.owpb.NetworkInterfaceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.NetworkInterfaceInfo;
  return proto.owpb.NetworkInterfaceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.NetworkInterfaceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.NetworkInterfaceInfo}
 */
proto.owpb.NetworkInterfaceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMac(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHaveGateway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.NetworkInterfaceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.NetworkInterfaceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.NetworkInterfaceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.NetworkInterfaceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMac();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHaveGateway();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string mac = 1;
 * @return {string}
 */
proto.owpb.NetworkInterfaceInfo.prototype.getMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.NetworkInterfaceInfo} returns this
 */
proto.owpb.NetworkInterfaceInfo.prototype.setMac = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.owpb.NetworkInterfaceInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.NetworkInterfaceInfo} returns this
 */
proto.owpb.NetworkInterfaceInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool have_gateway = 3;
 * @return {boolean}
 */
proto.owpb.NetworkInterfaceInfo.prototype.getHaveGateway = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.NetworkInterfaceInfo} returns this
 */
proto.owpb.NetworkInterfaceInfo.prototype.setHaveGateway = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.NetworkInterfaceInfoV6.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.NetworkInterfaceInfoV6.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.NetworkInterfaceInfoV6} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.NetworkInterfaceInfoV6.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.NetworkInterfaceInfoV6}
 */
proto.owpb.NetworkInterfaceInfoV6.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.NetworkInterfaceInfoV6;
  return proto.owpb.NetworkInterfaceInfoV6.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.NetworkInterfaceInfoV6} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.NetworkInterfaceInfoV6}
 */
proto.owpb.NetworkInterfaceInfoV6.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.NetworkInterfaceInfoV6.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.NetworkInterfaceInfoV6.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.NetworkInterfaceInfoV6} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.NetworkInterfaceInfoV6.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.owpb.NetworkInterfaceInfoV6.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.NetworkInterfaceInfoV6} returns this
 */
proto.owpb.NetworkInterfaceInfoV6.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.WindowsUserSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.WindowsUserSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.WindowsUserSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WindowsUserSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inetAccess: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isDeviceInetAccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    deviceLock: jspb.Message.getFieldWithDefault(msg, 4, 0),
    inetStrictCategoryId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    webHistoryLogFlags: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appAccess: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isDeviceAppAccess: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    appAccessDesktopOrCategoryId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    appHistoryLogFlags: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.WindowsUserSettings}
 */
proto.owpb.WindowsUserSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.WindowsUserSettings;
  return proto.owpb.WindowsUserSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.WindowsUserSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.WindowsUserSettings}
 */
proto.owpb.WindowsUserSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 2:
      var value = /** @type {!proto.owpb.InetControlState} */ (reader.readEnum());
      msg.setInetAccess(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeviceInetAccess(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceLock(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInetStrictCategoryId(value);
      break;
    case 6:
      var value = /** @type {!proto.owpb.WebHistoryLogFlags} */ (reader.readEnum());
      msg.setWebHistoryLogFlags(value);
      break;
    case 7:
      var value = /** @type {!proto.owpb.AppControlState} */ (reader.readEnum());
      msg.setAppAccess(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeviceAppAccess(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppAccessDesktopOrCategoryId(value);
      break;
    case 10:
      var value = /** @type {!proto.owpb.AppHistoryLogFlags} */ (reader.readEnum());
      msg.setAppHistoryLogFlags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.WindowsUserSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.WindowsUserSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.WindowsUserSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WindowsUserSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInetAccess();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsDeviceInetAccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDeviceLock();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInetStrictCategoryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebHistoryLogFlags();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAppAccess();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsDeviceAppAccess();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAppAccessDesktopOrCategoryId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAppHistoryLogFlags();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string role_id = 1;
 * @return {string}
 */
proto.owpb.WindowsUserSettings.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional InetControlState inet_access = 2;
 * @return {!proto.owpb.InetControlState}
 */
proto.owpb.WindowsUserSettings.prototype.getInetAccess = function() {
  return /** @type {!proto.owpb.InetControlState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.owpb.InetControlState} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setInetAccess = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool is_device_inet_access = 3;
 * @return {boolean}
 */
proto.owpb.WindowsUserSettings.prototype.getIsDeviceInetAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setIsDeviceInetAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 device_lock = 4;
 * @return {number}
 */
proto.owpb.WindowsUserSettings.prototype.getDeviceLock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setDeviceLock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string inet_strict_category_id = 5;
 * @return {string}
 */
proto.owpb.WindowsUserSettings.prototype.getInetStrictCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setInetStrictCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional WebHistoryLogFlags web_history_log_flags = 6;
 * @return {!proto.owpb.WebHistoryLogFlags}
 */
proto.owpb.WindowsUserSettings.prototype.getWebHistoryLogFlags = function() {
  return /** @type {!proto.owpb.WebHistoryLogFlags} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.owpb.WebHistoryLogFlags} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setWebHistoryLogFlags = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional AppControlState app_access = 7;
 * @return {!proto.owpb.AppControlState}
 */
proto.owpb.WindowsUserSettings.prototype.getAppAccess = function() {
  return /** @type {!proto.owpb.AppControlState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.owpb.AppControlState} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setAppAccess = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_device_app_access = 8;
 * @return {boolean}
 */
proto.owpb.WindowsUserSettings.prototype.getIsDeviceAppAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setIsDeviceAppAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string app_access_desktop_or_category_id = 9;
 * @return {string}
 */
proto.owpb.WindowsUserSettings.prototype.getAppAccessDesktopOrCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setAppAccessDesktopOrCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AppHistoryLogFlags app_history_log_flags = 10;
 * @return {!proto.owpb.AppHistoryLogFlags}
 */
proto.owpb.WindowsUserSettings.prototype.getAppHistoryLogFlags = function() {
  return /** @type {!proto.owpb.AppHistoryLogFlags} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.owpb.AppHistoryLogFlags} value
 * @return {!proto.owpb.WindowsUserSettings} returns this
 */
proto.owpb.WindowsUserSettings.prototype.setAppHistoryLogFlags = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.WindowsUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.WindowsUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.WindowsUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WindowsUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    session: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sessionState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    displaysCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sessionType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    clientAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userSettings: (f = msg.getUserSettings()) && proto.owpb.WindowsUserSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.WindowsUserInfo}
 */
proto.owpb.WindowsUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.WindowsUserInfo;
  return proto.owpb.WindowsUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.WindowsUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.WindowsUserInfo}
 */
proto.owpb.WindowsUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSession(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSessionState(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplaysCount(value);
      break;
    case 7:
      var value = /** @type {!proto.owpb.DeviceSessionType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientAddress(value);
      break;
    case 9:
      var value = new proto.owpb.WindowsUserSettings;
      reader.readMessage(value,proto.owpb.WindowsUserSettings.deserializeBinaryFromReader);
      msg.setUserSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.WindowsUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.WindowsUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.WindowsUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WindowsUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSession();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSessionState();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDisplaysCount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getClientAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserSettings();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.owpb.WindowsUserSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.owpb.WindowsUserInfo.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain_name = 2;
 * @return {string}
 */
proto.owpb.WindowsUserInfo.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.owpb.WindowsUserInfo.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 session = 4;
 * @return {number}
 */
proto.owpb.WindowsUserInfo.prototype.getSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setSession = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 session_state = 5;
 * @return {number}
 */
proto.owpb.WindowsUserInfo.prototype.getSessionState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setSessionState = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 displays_count = 6;
 * @return {number}
 */
proto.owpb.WindowsUserInfo.prototype.getDisplaysCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setDisplaysCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DeviceSessionType session_type = 7;
 * @return {!proto.owpb.DeviceSessionType}
 */
proto.owpb.WindowsUserInfo.prototype.getSessionType = function() {
  return /** @type {!proto.owpb.DeviceSessionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.owpb.DeviceSessionType} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string client_address = 8;
 * @return {string}
 */
proto.owpb.WindowsUserInfo.prototype.getClientAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.setClientAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional WindowsUserSettings user_settings = 9;
 * @return {?proto.owpb.WindowsUserSettings}
 */
proto.owpb.WindowsUserInfo.prototype.getUserSettings = function() {
  return /** @type{?proto.owpb.WindowsUserSettings} */ (
    jspb.Message.getWrapperField(this, proto.owpb.WindowsUserSettings, 9));
};


/**
 * @param {?proto.owpb.WindowsUserSettings|undefined} value
 * @return {!proto.owpb.WindowsUserInfo} returns this
*/
proto.owpb.WindowsUserInfo.prototype.setUserSettings = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.WindowsUserInfo} returns this
 */
proto.owpb.WindowsUserInfo.prototype.clearUserSettings = function() {
  return this.setUserSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.WindowsUserInfo.prototype.hasUserSettings = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.Country}
 */
proto.owpb.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.Country;
  return proto.owpb.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.Country}
 */
proto.owpb.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.owpb.Country.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Country} returns this
 */
proto.owpb.Country.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.owpb.Country.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Country} returns this
 */
proto.owpb.Country.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.Language.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.Language.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.Language} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Language.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.Language}
 */
proto.owpb.Language.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.Language;
  return proto.owpb.Language.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.Language} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.Language}
 */
proto.owpb.Language.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.Language.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.Language.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.Language} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.Language.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.owpb.Language.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Language} returns this
 */
proto.owpb.Language.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.owpb.Language.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.Language} returns this
 */
proto.owpb.Language.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.SystemI18nInfo.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.SystemI18nInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.SystemI18nInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.SystemI18nInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.SystemI18nInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    languagesList: jspb.Message.toObjectList(msg.getLanguagesList(),
    proto.owpb.Language.toObject, includeInstance),
    currenciesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    proto.owpb.Country.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.SystemI18nInfo}
 */
proto.owpb.SystemI18nInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.SystemI18nInfo;
  return proto.owpb.SystemI18nInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.SystemI18nInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.SystemI18nInfo}
 */
proto.owpb.SystemI18nInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.Language;
      reader.readMessage(value,proto.owpb.Language.deserializeBinaryFromReader);
      msg.addLanguages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    case 3:
      var value = new proto.owpb.Country;
      reader.readMessage(value,proto.owpb.Country.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.SystemI18nInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.SystemI18nInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.SystemI18nInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.SystemI18nInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.Language.serializeBinaryToWriter
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.owpb.Country.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Language languages = 1;
 * @return {!Array<!proto.owpb.Language>}
 */
proto.owpb.SystemI18nInfo.prototype.getLanguagesList = function() {
  return /** @type{!Array<!proto.owpb.Language>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.Language, 1));
};


/**
 * @param {!Array<!proto.owpb.Language>} value
 * @return {!proto.owpb.SystemI18nInfo} returns this
*/
proto.owpb.SystemI18nInfo.prototype.setLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.Language=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.Language}
 */
proto.owpb.SystemI18nInfo.prototype.addLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.Language, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.SystemI18nInfo} returns this
 */
proto.owpb.SystemI18nInfo.prototype.clearLanguagesList = function() {
  return this.setLanguagesList([]);
};


/**
 * repeated string currencies = 2;
 * @return {!Array<string>}
 */
proto.owpb.SystemI18nInfo.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.SystemI18nInfo} returns this
 */
proto.owpb.SystemI18nInfo.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.SystemI18nInfo} returns this
 */
proto.owpb.SystemI18nInfo.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.SystemI18nInfo} returns this
 */
proto.owpb.SystemI18nInfo.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};


/**
 * repeated Country countries = 3;
 * @return {!Array<!proto.owpb.Country>}
 */
proto.owpb.SystemI18nInfo.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.owpb.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.Country, 3));
};


/**
 * @param {!Array<!proto.owpb.Country>} value
 * @return {!proto.owpb.SystemI18nInfo} returns this
*/
proto.owpb.SystemI18nInfo.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.owpb.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.Country}
 */
proto.owpb.SystemI18nInfo.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.owpb.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.SystemI18nInfo} returns this
 */
proto.owpb.SystemI18nInfo.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};


/**
 * @enum {number}
 */
proto.owpb.AppType = {
  UNKNOWN_APP_TYPE: 0,
  CONSOLE_APP: 1,
  AGENT_APP: 2,
  PROXY_SERVER_APP: 3,
  INSTALLER_APP: 4,
  WEB_APP: 5,
  TOOL_APP: 6,
  UNINSTALLER_APP: 7
};

/**
 * @enum {number}
 */
proto.owpb.ApiErrorType = {
  AUTH_UNAUTHENTICATED: 0,
  AUTH_USER_NOT_ASIGNED: 1,
  AUTH_USER_NOT_ACTIVE: 2,
  AUTH_INVALID_CUSTOMER_ID: 3,
  AUTH_INVALID_INSTALLATION_ID: 4,
  AUTH_INVALID_REQUEST: 5,
  AUTH_INVALID_AGENT_ID: 6,
  PASSWORD_NOT_MEET_REQUIREMENTS: 7,
  PERMISSION_DENIED: 8,
  NULL_REQUEST: 9,
  INTERNAL: 10,
  INVALID_ID: 11,
  INVALID_TOPIC_ID: 12,
  INVALID_SUBSCRIBER_ID: 13,
  VALIDATION_ERROR: 14,
  CANT_DELETE_NOT_EMPTY_GROUP: 15,
  CANT_DELETE_ROOT_GROUP: 16,
  USER_EXIST: 17,
  RPC_FILE_NOT_EXISTS: 18,
  RPC_UNAVALIABLE_ON_DEVICE_TYPE: 19,
  RPC_DENIED: 20,
  RPC_DENIED_BY_WINDOWS_USER: 21,
  RPC_DENIED_WINDOWS_USER_NOT_ACTIVE: 22,
  RPC_SCREENSHOT_DISPLAY_DISABLED: 23,
  AUTH_INVALID_TOKEN: 24,
  AUTH_INVALID_USER: 25,
  RPC_AGENT_NOT_CONNECTED: 26,
  RPC_TIMEOUT: 27,
  AUTH_CUSTOMER_NOT_ACTIVE_OR_KEY_EXPIRED: 28,
  AUTH_USER_NEED_MIGRATE_FROM_V4: 29,
  RPC_DENIED_IN_CURRENT_PLAN: 30
};

/**
 * @enum {number}
 */
proto.owpb.ControlState = {
  CHECK: 0,
  BLOCK: 1,
  ALLOW: 2,
  DEPENDS_ON_USER: 3
};

/**
 * @enum {number}
 */
proto.owpb.InetControlState = {
  INET_CHECK: 0,
  INET_BLOCK: 1,
  INET_ALLOW: 2,
  INET_STRICT: 4
};

/**
 * @enum {number}
 */
proto.owpb.AppControlState = {
  APP_CHECK: 0,
  APP_BLOCK: 1,
  APP_ALLOW: 2,
  APP_STRICT_TO_DESKTOP: 4,
  APP_USE_BLACK_LIST: 5,
  APP_USE_WHITE_LIST: 6
};

/**
 * @enum {number}
 */
proto.owpb.AuthTokenPermissionFlags = {
  UNKNOWN_AUTH_TOKEN_FLAGS: 0,
  AUTH_TOKEN_AGENT_INSTALL: 1,
  AUTH_TOKEN_AGENT_UNINSTALL: 2
};

/**
 * @enum {number}
 */
proto.owpb.DeviceType = {
  UNKNOWN_DEVICE_TYPE: 0,
  DESKTOP: 1,
  TERMINAL_SERVER: 2,
  TERMINAL_CLIENT: 3
};

/**
 * @enum {number}
 */
proto.owpb.DeviceSessionType = {
  UNKNOWN_DEVICE_SESSION_TYPE: 0,
  CONSOLE: 1,
  TERMINAL: 2,
  RDP: 3
};

/**
 * @enum {number}
 */
proto.owpb.DeviceGroupsSelectMode = {
  DEVICE_GROUPS_SELECT_MODE_NONE: 0,
  DEVICE_GROUPS_SELECT_ALL: 1,
  DEVICE_GROUPS_SELECT_DEPEND_ON_AGENT_GROUP: 2,
  DEVICE_GROUPS_SELECT_ONE_GROUP_BY_USER: 3
};

/**
 * @enum {number}
 */
proto.owpb.ProductType = {
  UNKNOWN_PRODUCT_TYPE: 0,
  OPWEB: 1,
  ADMINIFY: 2
};

/**
 * @enum {number}
 */
proto.owpb.SearchEnginesFlags = {
  SEARCH_ENGINE_DISABLED: 0,
  FORCE_SAFE: 1,
  CHECK_QUERY: 2,
  DENY_IMAGES: 4
};

/**
 * @enum {number}
 */
proto.owpb.UserAuthType = {
  UNKNOWN_USER_AUTH_TYPE: 0,
  USER_AUTH_BY_PASSWD: 1,
  USER_AUTH_BY_APP_TOKEN: 2,
  USER_AUTH_BY_OAUTH2: 3,
  USER_AUTH_BY_IMPERSONATION: 4
};

/**
 * @enum {number}
 */
proto.owpb.UserStatus = {
  UNKNOWN_USER_STATUS: 0,
  USER_WAITING_FOR_ACTIVATION: 1,
  USER_ACTIVE: 2
};

/**
 * @enum {number}
 */
proto.owpb.WebHistoryLogFlags = {
  WEB_HISTORY_LOG_DISABLED: 0,
  URL_BLOCKED: 2,
  URL_VISITED: 4
};

/**
 * @enum {number}
 */
proto.owpb.AppHistoryLogFlags = {
  APP_HISTORY_LOG_DISABLED: 0,
  APP_HISTORY_LOG_ENABLED: 1
};

/**
 * @enum {number}
 */
proto.owpb.AppHistoryItemType = {
  UNKNOWN_APP_HISTORY_ITEM_TYPE: 0,
  APP_HISTORY_ITEM_IDLE: 1,
  APP_HISTORY_ITEM_WEB_BROWSER: 4
};

goog.object.extend(exports, proto.owpb);
