/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as updates_service_pb from './updates_service_pb';


export class UpdatesServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCollectionsUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    updates_service_pb.MultipleCollectionsUpdate,
    (request: updates_service_pb.MultipleCollectionsUpdateRequest) => {
      return request.serializeBinary();
    },
    updates_service_pb.MultipleCollectionsUpdate.deserializeBinary
  );

  getCollectionsUpdates(
    request: updates_service_pb.MultipleCollectionsUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<updates_service_pb.MultipleCollectionsUpdate>;

  getCollectionsUpdates(
    request: updates_service_pb.MultipleCollectionsUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: updates_service_pb.MultipleCollectionsUpdate) => void): grpcWeb.ClientReadableStream<updates_service_pb.MultipleCollectionsUpdate>;

  getCollectionsUpdates(
    request: updates_service_pb.MultipleCollectionsUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: updates_service_pb.MultipleCollectionsUpdate) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.UpdatesService/GetCollectionsUpdates',
        request,
        metadata || {},
        this.methodInfoGetCollectionsUpdates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.UpdatesService/GetCollectionsUpdates',
    request,
    metadata || {},
    this.methodInfoGetCollectionsUpdates);
  }

  methodInfoGetVersions = new grpcWeb.AbstractClientBase.MethodInfo(
    updates_service_pb.VersionsCollection,
    (request: updates_service_pb.GetVersionsRequest) => {
      return request.serializeBinary();
    },
    updates_service_pb.VersionsCollection.deserializeBinary
  );

  getVersions(
    request: updates_service_pb.GetVersionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<updates_service_pb.VersionsCollection>;

  getVersions(
    request: updates_service_pb.GetVersionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: updates_service_pb.VersionsCollection) => void): grpcWeb.ClientReadableStream<updates_service_pb.VersionsCollection>;

  getVersions(
    request: updates_service_pb.GetVersionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: updates_service_pb.VersionsCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.UpdatesService/GetVersions',
        request,
        metadata || {},
        this.methodInfoGetVersions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.UpdatesService/GetVersions',
    request,
    metadata || {},
    this.methodInfoGetVersions);
  }

}

