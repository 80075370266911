/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as trial_service_pb from './trial_service_pb';


export class TrialServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateTrialCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    trial_service_pb.CreateTrialCustomerResponse,
    (request: trial_service_pb.CreateTrialCustomerRequest) => {
      return request.serializeBinary();
    },
    trial_service_pb.CreateTrialCustomerResponse.deserializeBinary
  );

  createTrialCustomer(
    request: trial_service_pb.CreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<trial_service_pb.CreateTrialCustomerResponse>;

  createTrialCustomer(
    request: trial_service_pb.CreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: trial_service_pb.CreateTrialCustomerResponse) => void): grpcWeb.ClientReadableStream<trial_service_pb.CreateTrialCustomerResponse>;

  createTrialCustomer(
    request: trial_service_pb.CreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: trial_service_pb.CreateTrialCustomerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.TrialService/CreateTrialCustomer',
        request,
        metadata || {},
        this.methodInfoCreateTrialCustomer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.TrialService/CreateTrialCustomer',
    request,
    metadata || {},
    this.methodInfoCreateTrialCustomer);
  }

  methodInfoCreateOAuth2TrialCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    trial_service_pb.CreateOAuth2TrialCustomerResponse,
    (request: trial_service_pb.CreateOAuth2TrialCustomerRequest) => {
      return request.serializeBinary();
    },
    trial_service_pb.CreateOAuth2TrialCustomerResponse.deserializeBinary
  );

  createOAuth2TrialCustomer(
    request: trial_service_pb.CreateOAuth2TrialCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<trial_service_pb.CreateOAuth2TrialCustomerResponse>;

  createOAuth2TrialCustomer(
    request: trial_service_pb.CreateOAuth2TrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: trial_service_pb.CreateOAuth2TrialCustomerResponse) => void): grpcWeb.ClientReadableStream<trial_service_pb.CreateOAuth2TrialCustomerResponse>;

  createOAuth2TrialCustomer(
    request: trial_service_pb.CreateOAuth2TrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: trial_service_pb.CreateOAuth2TrialCustomerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.TrialService/CreateOAuth2TrialCustomer',
        request,
        metadata || {},
        this.methodInfoCreateOAuth2TrialCustomer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.TrialService/CreateOAuth2TrialCustomer',
    request,
    metadata || {},
    this.methodInfoCreateOAuth2TrialCustomer);
  }

  methodInfoConfirmCreateTrialCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    trial_service_pb.ConfirmCreateTrialCustomerResponse,
    (request: trial_service_pb.ConfirmCreateTrialCustomerRequest) => {
      return request.serializeBinary();
    },
    trial_service_pb.ConfirmCreateTrialCustomerResponse.deserializeBinary
  );

  confirmCreateTrialCustomer(
    request: trial_service_pb.ConfirmCreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<trial_service_pb.ConfirmCreateTrialCustomerResponse>;

  confirmCreateTrialCustomer(
    request: trial_service_pb.ConfirmCreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: trial_service_pb.ConfirmCreateTrialCustomerResponse) => void): grpcWeb.ClientReadableStream<trial_service_pb.ConfirmCreateTrialCustomerResponse>;

  confirmCreateTrialCustomer(
    request: trial_service_pb.ConfirmCreateTrialCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: trial_service_pb.ConfirmCreateTrialCustomerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.TrialService/ConfirmCreateTrialCustomer',
        request,
        metadata || {},
        this.methodInfoConfirmCreateTrialCustomer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.TrialService/ConfirmCreateTrialCustomer',
    request,
    metadata || {},
    this.methodInfoConfirmCreateTrialCustomer);
  }

}

