import { urlStructure } from '@/constants/urlStructure';


export const apiUrl = 'https://envoy.opiekunweb.pl';
// export const apiUrl = 'http://10.1.1.1:8081';
export const pricelistUrl = 'https://opiekunucznia.pl';
export const termsAndServiceUrl = 'https://opiekunucznia.pl';
export const privacyPolicyUrl = 'https://opiekunucznia.pl/rodo';
export const helpPageUrl = 'https://pomoc.opiekunweb.pl/!opiekun5/doku.php';
export const downloadFilesPageUrl = 'https://pomoc.opiekunweb.pl/';

export const consoleLink = 'https://pliki.opiekun.pl/opiekunweb5/instalator/?console';
export const agentLink = 'https://pliki.opiekun.pl/opiekunweb5/instalator/?agent';

export const defaultApiCurrency = 'PLN';
export const przelewy24SupportedCurrencies = ['PLN', 'EUR'];

export const defaultPlanType = 1;

export const defaultNumberOfDevices = 10;

export const deleteAccountEmailAddress = 'pomoc@opiekun.pl';

export const defaultLanguage = 'pl';
export const fallbackLanguage = 'pl';

export const defaultFallbackPageForInsufficientPermissions = urlStructure.accessControlPreview;

export const defaultMainContainerWidth = 1200;
export const minSupportedWidth = 768;

// most screens have proportion 16:9
export const screenProportions = 16 / 9;
export const screenPreviewHeaderAndFooterHeight = 28;
export const screenPreviewMinWidth = 120;

export const appHeaderHeight = 100;
export const accessControlsMobileWidth = 1565;
export const accessControlMainContainerMinWidth = 950;

export const sessionStoreKey = {
  user: 'user-store',
  collectionItems: (collectionId: string) => `collection-${collectionId}-items`,
  collectionVersion: (collectionId: string) => `collection-${collectionId}-version`,
};

export const previewRefreshModalFrequency = 5; // seconds

export const appDateFormat = 'DD/MM/YYYY';
export const appTimeFormat = 'HH:mm';
export const appDateTimeFormat = `${appDateFormat} ${appTimeFormat}`;

export const defaultTablePageSize = 50;

