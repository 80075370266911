// source: history_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var basic_pb = require('./basic_pb.js');
goog.object.extend(proto, basic_pb);
goog.exportSymbol('proto.owpb.AppFilterState', null, global);
goog.exportSymbol('proto.owpb.AppHistoryCollection', null, global);
goog.exportSymbol('proto.owpb.AppHistoryItem', null, global);
goog.exportSymbol('proto.owpb.AppHistoryStatsCollection', null, global);
goog.exportSymbol('proto.owpb.AppHistoryStatsItem', null, global);
goog.exportSymbol('proto.owpb.DeleteAppHistoryRequest', null, global);
goog.exportSymbol('proto.owpb.DeleteAppHistoryResponse', null, global);
goog.exportSymbol('proto.owpb.DeleteWebHistoryRequest', null, global);
goog.exportSymbol('proto.owpb.DeleteWebHistoryResponse', null, global);
goog.exportSymbol('proto.owpb.DomainAndUser', null, global);
goog.exportSymbol('proto.owpb.DomainAndUserCollection', null, global);
goog.exportSymbol('proto.owpb.FieldFilter', null, global);
goog.exportSymbol('proto.owpb.GetAppHistoryOldestTimestampRequest', null, global);
goog.exportSymbol('proto.owpb.GetAppHistoryRequest', null, global);
goog.exportSymbol('proto.owpb.GetAppHistoryStatsRequest', null, global);
goog.exportSymbol('proto.owpb.GetAppHistoryUsersRequest', null, global);
goog.exportSymbol('proto.owpb.GetUrlDomainHistoryStatsRequest', null, global);
goog.exportSymbol('proto.owpb.GetWebHistoryOldestTimestampRequest', null, global);
goog.exportSymbol('proto.owpb.GetWebHistoryRequest', null, global);
goog.exportSymbol('proto.owpb.GetWebHistoryUsersRequest', null, global);
goog.exportSymbol('proto.owpb.HistoryOldestTimestampResponse', null, global);
goog.exportSymbol('proto.owpb.UrlDomainHistoryStatsCollection', null, global);
goog.exportSymbol('proto.owpb.UrlDomainHistoryStatsItem', null, global);
goog.exportSymbol('proto.owpb.WebHistoryCollection', null, global);
goog.exportSymbol('proto.owpb.WebHistoryItem', null, global);
goog.exportSymbol('proto.owpb.WebHistoryItemFlag', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DomainAndUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.DomainAndUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DomainAndUser.displayName = 'proto.owpb.DomainAndUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.FieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.FieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.FieldFilter.displayName = 'proto.owpb.FieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.HistoryOldestTimestampResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.HistoryOldestTimestampResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.HistoryOldestTimestampResponse.displayName = 'proto.owpb.HistoryOldestTimestampResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.AppHistoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.AppHistoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.AppHistoryItem.displayName = 'proto.owpb.AppHistoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetAppHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetAppHistoryRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetAppHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetAppHistoryRequest.displayName = 'proto.owpb.GetAppHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.AppHistoryCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.AppHistoryCollection.repeatedFields_, null);
};
goog.inherits(proto.owpb.AppHistoryCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.AppHistoryCollection.displayName = 'proto.owpb.AppHistoryCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.AppHistoryStatsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.AppHistoryStatsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.AppHistoryStatsItem.displayName = 'proto.owpb.AppHistoryStatsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetAppHistoryStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetAppHistoryStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetAppHistoryStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetAppHistoryStatsRequest.displayName = 'proto.owpb.GetAppHistoryStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.AppHistoryStatsCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.AppHistoryStatsCollection.repeatedFields_, null);
};
goog.inherits(proto.owpb.AppHistoryStatsCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.AppHistoryStatsCollection.displayName = 'proto.owpb.AppHistoryStatsCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetAppHistoryOldestTimestampRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.GetAppHistoryOldestTimestampRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetAppHistoryOldestTimestampRequest.displayName = 'proto.owpb.GetAppHistoryOldestTimestampRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetAppHistoryUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetAppHistoryUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetAppHistoryUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetAppHistoryUsersRequest.displayName = 'proto.owpb.GetAppHistoryUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetWebHistoryUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetWebHistoryUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetWebHistoryUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetWebHistoryUsersRequest.displayName = 'proto.owpb.GetWebHistoryUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DomainAndUserCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.DomainAndUserCollection.repeatedFields_, null);
};
goog.inherits(proto.owpb.DomainAndUserCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DomainAndUserCollection.displayName = 'proto.owpb.DomainAndUserCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.UrlDomainHistoryStatsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.UrlDomainHistoryStatsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.UrlDomainHistoryStatsItem.displayName = 'proto.owpb.UrlDomainHistoryStatsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetUrlDomainHistoryStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetUrlDomainHistoryStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetUrlDomainHistoryStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetUrlDomainHistoryStatsRequest.displayName = 'proto.owpb.GetUrlDomainHistoryStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.UrlDomainHistoryStatsCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.UrlDomainHistoryStatsCollection.repeatedFields_, null);
};
goog.inherits(proto.owpb.UrlDomainHistoryStatsCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.UrlDomainHistoryStatsCollection.displayName = 'proto.owpb.UrlDomainHistoryStatsCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.WebHistoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.WebHistoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.WebHistoryItem.displayName = 'proto.owpb.WebHistoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DeleteAppHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.DeleteAppHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DeleteAppHistoryRequest.displayName = 'proto.owpb.DeleteAppHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DeleteAppHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.DeleteAppHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DeleteAppHistoryResponse.displayName = 'proto.owpb.DeleteAppHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DeleteWebHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.DeleteWebHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DeleteWebHistoryRequest.displayName = 'proto.owpb.DeleteWebHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.DeleteWebHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.DeleteWebHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.DeleteWebHistoryResponse.displayName = 'proto.owpb.DeleteWebHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetWebHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.GetWebHistoryRequest.repeatedFields_, null);
};
goog.inherits(proto.owpb.GetWebHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetWebHistoryRequest.displayName = 'proto.owpb.GetWebHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.WebHistoryCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.owpb.WebHistoryCollection.repeatedFields_, null);
};
goog.inherits(proto.owpb.WebHistoryCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.WebHistoryCollection.displayName = 'proto.owpb.WebHistoryCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetWebHistoryOldestTimestampRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.GetWebHistoryOldestTimestampRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetWebHistoryOldestTimestampRequest.displayName = 'proto.owpb.GetWebHistoryOldestTimestampRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DomainAndUser.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DomainAndUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DomainAndUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DomainAndUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.DomainAndUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DomainAndUser;
  return proto.owpb.DomainAndUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DomainAndUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.DomainAndUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DomainAndUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DomainAndUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DomainAndUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DomainAndUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string domain_name = 1;
 * @return {string}
 */
proto.owpb.DomainAndUser.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.DomainAndUser} returns this
 */
proto.owpb.DomainAndUser.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.owpb.DomainAndUser.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.DomainAndUser} returns this
 */
proto.owpb.DomainAndUser.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.owpb.DomainAndUser.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.DomainAndUser} returns this
 */
proto.owpb.DomainAndUser.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.FieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.FieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.FieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.FieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filedName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.FieldFilter}
 */
proto.owpb.FieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.FieldFilter;
  return proto.owpb.FieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.FieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.FieldFilter}
 */
proto.owpb.FieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiledName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.FieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.FieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.FieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.FieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiledName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string filed_name = 1;
 * @return {string}
 */
proto.owpb.FieldFilter.prototype.getFiledName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.FieldFilter} returns this
 */
proto.owpb.FieldFilter.prototype.setFiledName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.owpb.FieldFilter.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.FieldFilter} returns this
 */
proto.owpb.FieldFilter.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.HistoryOldestTimestampResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.HistoryOldestTimestampResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.HistoryOldestTimestampResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.HistoryOldestTimestampResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.HistoryOldestTimestampResponse}
 */
proto.owpb.HistoryOldestTimestampResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.HistoryOldestTimestampResponse;
  return proto.owpb.HistoryOldestTimestampResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.HistoryOldestTimestampResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.HistoryOldestTimestampResponse}
 */
proto.owpb.HistoryOldestTimestampResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.HistoryOldestTimestampResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.HistoryOldestTimestampResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.HistoryOldestTimestampResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.HistoryOldestTimestampResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 timestamp = 1;
 * @return {number}
 */
proto.owpb.HistoryOldestTimestampResponse.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.HistoryOldestTimestampResponse} returns this
 */
proto.owpb.HistoryOldestTimestampResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.AppHistoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.AppHistoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.AppHistoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    domainName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 10, 0),
    exeName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    exeDescription: jspb.Message.getFieldWithDefault(msg, 12, ""),
    title: jspb.Message.getFieldWithDefault(msg, 13, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 14, 0),
    url: jspb.Message.getFieldWithDefault(msg, 15, ""),
    urlDomain: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.AppHistoryItem}
 */
proto.owpb.AppHistoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.AppHistoryItem;
  return proto.owpb.AppHistoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.AppHistoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.AppHistoryItem}
 */
proto.owpb.AppHistoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.owpb.AppHistoryItemType} */ (reader.readEnum());
      msg.setItemType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeDescription(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.AppHistoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.AppHistoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.AppHistoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getExeName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExeDescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUrlDomain();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AppHistoryItemType item_type = 2;
 * @return {!proto.owpb.AppHistoryItemType}
 */
proto.owpb.AppHistoryItem.prototype.getItemType = function() {
  return /** @type {!proto.owpb.AppHistoryItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.owpb.AppHistoryItemType} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_id = 4;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_name = 5;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_name = 6;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string domain_name = 7;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string full_name = 8;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string role_id = 9;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint64 timestamp = 10;
 * @return {number}
 */
proto.owpb.AppHistoryItem.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string exe_name = 11;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getExeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setExeName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string exe_description = 12;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getExeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setExeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string title = 13;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 duration = 14;
 * @return {number}
 */
proto.owpb.AppHistoryItem.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string url = 15;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string url_domain = 16;
 * @return {string}
 */
proto.owpb.AppHistoryItem.prototype.getUrlDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryItem} returns this
 */
proto.owpb.AppHistoryItem.prototype.setUrlDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetAppHistoryRequest.repeatedFields_ = [7,8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetAppHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetAppHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetAppHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    take: jspb.Message.getFieldWithDefault(msg, 4, 0),
    skip: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reverseOrder: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.owpb.DomainAndUser.toObject, includeInstance),
    sortedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.owpb.FieldFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetAppHistoryRequest}
 */
proto.owpb.GetAppHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetAppHistoryRequest;
  return proto.owpb.GetAppHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetAppHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetAppHistoryRequest}
 */
proto.owpb.GetAppHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTake(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSkip(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverseOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    case 8:
      var value = new proto.owpb.DomainAndUser;
      reader.readMessage(value,proto.owpb.DomainAndUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortedBy(value);
      break;
    case 11:
      var value = new proto.owpb.FieldFilter;
      reader.readMessage(value,proto.owpb.FieldFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetAppHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetAppHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetAppHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getReverseOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.owpb.DomainAndUser.serializeBinaryToWriter
    );
  }
  f = message.getSortedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.owpb.FieldFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetAppHistoryRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetAppHistoryRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string start_id = 3;
 * @return {string}
 */
proto.owpb.GetAppHistoryRequest.prototype.getStartId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setStartId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 take = 4;
 * @return {number}
 */
proto.owpb.GetAppHistoryRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 skip = 5;
 * @return {number}
 */
proto.owpb.GetAppHistoryRequest.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool reverse_order = 6;
 * @return {boolean}
 */
proto.owpb.GetAppHistoryRequest.prototype.getReverseOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setReverseOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string devices_id = 7;
 * @return {!Array<string>}
 */
proto.owpb.GetAppHistoryRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};


/**
 * repeated DomainAndUser users = 8;
 * @return {!Array<!proto.owpb.DomainAndUser>}
 */
proto.owpb.GetAppHistoryRequest.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.owpb.DomainAndUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.DomainAndUser, 8));
};


/**
 * @param {!Array<!proto.owpb.DomainAndUser>} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
*/
proto.owpb.GetAppHistoryRequest.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.owpb.DomainAndUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.GetAppHistoryRequest.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.owpb.DomainAndUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string sorted_by = 10;
 * @return {string}
 */
proto.owpb.GetAppHistoryRequest.prototype.getSortedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.setSortedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated FieldFilter filters = 11;
 * @return {!Array<!proto.owpb.FieldFilter>}
 */
proto.owpb.GetAppHistoryRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.owpb.FieldFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.FieldFilter, 11));
};


/**
 * @param {!Array<!proto.owpb.FieldFilter>} value
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
*/
proto.owpb.GetAppHistoryRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.owpb.FieldFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.FieldFilter}
 */
proto.owpb.GetAppHistoryRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.owpb.FieldFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryRequest} returns this
 */
proto.owpb.GetAppHistoryRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.AppHistoryCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.AppHistoryCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.AppHistoryCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.AppHistoryCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.owpb.AppHistoryItem.toObject, includeInstance),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.AppHistoryCollection}
 */
proto.owpb.AppHistoryCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.AppHistoryCollection;
  return proto.owpb.AppHistoryCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.AppHistoryCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.AppHistoryCollection}
 */
proto.owpb.AppHistoryCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.AppHistoryItem;
      reader.readMessage(value,proto.owpb.AppHistoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.AppHistoryCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.AppHistoryCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.AppHistoryCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.AppHistoryItem.serializeBinaryToWriter
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated AppHistoryItem items = 1;
 * @return {!Array<!proto.owpb.AppHistoryItem>}
 */
proto.owpb.AppHistoryCollection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.owpb.AppHistoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.AppHistoryItem, 1));
};


/**
 * @param {!Array<!proto.owpb.AppHistoryItem>} value
 * @return {!proto.owpb.AppHistoryCollection} returns this
*/
proto.owpb.AppHistoryCollection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.AppHistoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.AppHistoryItem}
 */
proto.owpb.AppHistoryCollection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.AppHistoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.AppHistoryCollection} returns this
 */
proto.owpb.AppHistoryCollection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.owpb.AppHistoryCollection.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.AppHistoryCollection} returns this
 */
proto.owpb.AppHistoryCollection.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.AppHistoryStatsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.AppHistoryStatsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.AppHistoryStatsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryStatsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    exeDescription: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.AppHistoryStatsItem}
 */
proto.owpb.AppHistoryStatsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.AppHistoryStatsItem;
  return proto.owpb.AppHistoryStatsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.AppHistoryStatsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.AppHistoryStatsItem}
 */
proto.owpb.AppHistoryStatsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.AppHistoryStatsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.AppHistoryStatsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.AppHistoryStatsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryStatsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExeDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string exe_description = 1;
 * @return {string}
 */
proto.owpb.AppHistoryStatsItem.prototype.getExeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.AppHistoryStatsItem} returns this
 */
proto.owpb.AppHistoryStatsItem.prototype.setExeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.owpb.AppHistoryStatsItem.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.AppHistoryStatsItem} returns this
 */
proto.owpb.AppHistoryStatsItem.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetAppHistoryStatsRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetAppHistoryStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetAppHistoryStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    take: jspb.Message.getFieldWithDefault(msg, 3, 0),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.owpb.DomainAndUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetAppHistoryStatsRequest}
 */
proto.owpb.GetAppHistoryStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetAppHistoryStatsRequest;
  return proto.owpb.GetAppHistoryStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetAppHistoryStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetAppHistoryStatsRequest}
 */
proto.owpb.GetAppHistoryStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    case 5:
      var value = new proto.owpb.DomainAndUser;
      reader.readMessage(value,proto.owpb.DomainAndUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetAppHistoryStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetAppHistoryStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.owpb.DomainAndUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 take = 3;
 * @return {number}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string devices_id = 4;
 * @return {!Array<string>}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};


/**
 * repeated DomainAndUser users = 5;
 * @return {!Array<!proto.owpb.DomainAndUser>}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.owpb.DomainAndUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.DomainAndUser, 5));
};


/**
 * @param {!Array<!proto.owpb.DomainAndUser>} value
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
*/
proto.owpb.GetAppHistoryStatsRequest.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.owpb.DomainAndUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.owpb.DomainAndUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryStatsRequest} returns this
 */
proto.owpb.GetAppHistoryStatsRequest.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.AppHistoryStatsCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.AppHistoryStatsCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.AppHistoryStatsCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.AppHistoryStatsCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryStatsCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.owpb.AppHistoryStatsItem.toObject, includeInstance),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.AppHistoryStatsCollection}
 */
proto.owpb.AppHistoryStatsCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.AppHistoryStatsCollection;
  return proto.owpb.AppHistoryStatsCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.AppHistoryStatsCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.AppHistoryStatsCollection}
 */
proto.owpb.AppHistoryStatsCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.AppHistoryStatsItem;
      reader.readMessage(value,proto.owpb.AppHistoryStatsItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.AppHistoryStatsCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.AppHistoryStatsCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.AppHistoryStatsCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.AppHistoryStatsCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.AppHistoryStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated AppHistoryStatsItem items = 1;
 * @return {!Array<!proto.owpb.AppHistoryStatsItem>}
 */
proto.owpb.AppHistoryStatsCollection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.owpb.AppHistoryStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.AppHistoryStatsItem, 1));
};


/**
 * @param {!Array<!proto.owpb.AppHistoryStatsItem>} value
 * @return {!proto.owpb.AppHistoryStatsCollection} returns this
*/
proto.owpb.AppHistoryStatsCollection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.AppHistoryStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.AppHistoryStatsItem}
 */
proto.owpb.AppHistoryStatsCollection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.AppHistoryStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.AppHistoryStatsCollection} returns this
 */
proto.owpb.AppHistoryStatsCollection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.owpb.AppHistoryStatsCollection.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.AppHistoryStatsCollection} returns this
 */
proto.owpb.AppHistoryStatsCollection.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetAppHistoryOldestTimestampRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetAppHistoryOldestTimestampRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetAppHistoryOldestTimestampRequest}
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetAppHistoryOldestTimestampRequest;
  return proto.owpb.GetAppHistoryOldestTimestampRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetAppHistoryOldestTimestampRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetAppHistoryOldestTimestampRequest}
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetAppHistoryOldestTimestampRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetAppHistoryOldestTimestampRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryOldestTimestampRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetAppHistoryUsersRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetAppHistoryUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetAppHistoryUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetAppHistoryUsersRequest}
 */
proto.owpb.GetAppHistoryUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetAppHistoryUsersRequest;
  return proto.owpb.GetAppHistoryUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetAppHistoryUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetAppHistoryUsersRequest}
 */
proto.owpb.GetAppHistoryUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetAppHistoryUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetAppHistoryUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetAppHistoryUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryUsersRequest} returns this
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetAppHistoryUsersRequest} returns this
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string devices_id = 3;
 * @return {!Array<string>}
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetAppHistoryUsersRequest} returns this
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetAppHistoryUsersRequest} returns this
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetAppHistoryUsersRequest} returns this
 */
proto.owpb.GetAppHistoryUsersRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetWebHistoryUsersRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetWebHistoryUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetWebHistoryUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetWebHistoryUsersRequest}
 */
proto.owpb.GetWebHistoryUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetWebHistoryUsersRequest;
  return proto.owpb.GetWebHistoryUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetWebHistoryUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetWebHistoryUsersRequest}
 */
proto.owpb.GetWebHistoryUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetWebHistoryUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetWebHistoryUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryUsersRequest} returns this
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryUsersRequest} returns this
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string devices_id = 3;
 * @return {!Array<string>}
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetWebHistoryUsersRequest} returns this
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetWebHistoryUsersRequest} returns this
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetWebHistoryUsersRequest} returns this
 */
proto.owpb.GetWebHistoryUsersRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.DomainAndUserCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DomainAndUserCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DomainAndUserCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DomainAndUserCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DomainAndUserCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.owpb.DomainAndUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DomainAndUserCollection}
 */
proto.owpb.DomainAndUserCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DomainAndUserCollection;
  return proto.owpb.DomainAndUserCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DomainAndUserCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DomainAndUserCollection}
 */
proto.owpb.DomainAndUserCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.DomainAndUser;
      reader.readMessage(value,proto.owpb.DomainAndUser.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DomainAndUserCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DomainAndUserCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DomainAndUserCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DomainAndUserCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.DomainAndUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DomainAndUser items = 1;
 * @return {!Array<!proto.owpb.DomainAndUser>}
 */
proto.owpb.DomainAndUserCollection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.owpb.DomainAndUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.DomainAndUser, 1));
};


/**
 * @param {!Array<!proto.owpb.DomainAndUser>} value
 * @return {!proto.owpb.DomainAndUserCollection} returns this
*/
proto.owpb.DomainAndUserCollection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.DomainAndUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.DomainAndUserCollection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.DomainAndUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.DomainAndUserCollection} returns this
 */
proto.owpb.DomainAndUserCollection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.UrlDomainHistoryStatsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.UrlDomainHistoryStatsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UrlDomainHistoryStatsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.UrlDomainHistoryStatsItem}
 */
proto.owpb.UrlDomainHistoryStatsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.UrlDomainHistoryStatsItem;
  return proto.owpb.UrlDomainHistoryStatsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.UrlDomainHistoryStatsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.UrlDomainHistoryStatsItem}
 */
proto.owpb.UrlDomainHistoryStatsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.UrlDomainHistoryStatsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.UrlDomainHistoryStatsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UrlDomainHistoryStatsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string url_domain = 1;
 * @return {string}
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.getUrlDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.UrlDomainHistoryStatsItem} returns this
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.setUrlDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.UrlDomainHistoryStatsItem} returns this
 */
proto.owpb.UrlDomainHistoryStatsItem.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetUrlDomainHistoryStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetUrlDomainHistoryStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    take: jspb.Message.getFieldWithDefault(msg, 3, 0),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.owpb.DomainAndUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetUrlDomainHistoryStatsRequest;
  return proto.owpb.GetUrlDomainHistoryStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetUrlDomainHistoryStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    case 5:
      var value = new proto.owpb.DomainAndUser;
      reader.readMessage(value,proto.owpb.DomainAndUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetUrlDomainHistoryStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetUrlDomainHistoryStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.owpb.DomainAndUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 take = 3;
 * @return {number}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string devices_id = 4;
 * @return {!Array<string>}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};


/**
 * repeated DomainAndUser users = 5;
 * @return {!Array<!proto.owpb.DomainAndUser>}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.owpb.DomainAndUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.DomainAndUser, 5));
};


/**
 * @param {!Array<!proto.owpb.DomainAndUser>} value
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
*/
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.owpb.DomainAndUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.owpb.DomainAndUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetUrlDomainHistoryStatsRequest} returns this
 */
proto.owpb.GetUrlDomainHistoryStatsRequest.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.UrlDomainHistoryStatsCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.UrlDomainHistoryStatsCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.UrlDomainHistoryStatsCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UrlDomainHistoryStatsCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.owpb.UrlDomainHistoryStatsItem.toObject, includeInstance),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.UrlDomainHistoryStatsCollection}
 */
proto.owpb.UrlDomainHistoryStatsCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.UrlDomainHistoryStatsCollection;
  return proto.owpb.UrlDomainHistoryStatsCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.UrlDomainHistoryStatsCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.UrlDomainHistoryStatsCollection}
 */
proto.owpb.UrlDomainHistoryStatsCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.UrlDomainHistoryStatsItem;
      reader.readMessage(value,proto.owpb.UrlDomainHistoryStatsItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.UrlDomainHistoryStatsCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.UrlDomainHistoryStatsCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UrlDomainHistoryStatsCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.UrlDomainHistoryStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated UrlDomainHistoryStatsItem items = 1;
 * @return {!Array<!proto.owpb.UrlDomainHistoryStatsItem>}
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.owpb.UrlDomainHistoryStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.UrlDomainHistoryStatsItem, 1));
};


/**
 * @param {!Array<!proto.owpb.UrlDomainHistoryStatsItem>} value
 * @return {!proto.owpb.UrlDomainHistoryStatsCollection} returns this
*/
proto.owpb.UrlDomainHistoryStatsCollection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.UrlDomainHistoryStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.UrlDomainHistoryStatsItem}
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.UrlDomainHistoryStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.UrlDomainHistoryStatsCollection} returns this
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.UrlDomainHistoryStatsCollection} returns this
 */
proto.owpb.UrlDomainHistoryStatsCollection.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.WebHistoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.WebHistoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.WebHistoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WebHistoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    domainName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 10, 0),
    url: jspb.Message.getFieldWithDefault(msg, 11, ""),
    title: jspb.Message.getFieldWithDefault(msg, 12, ""),
    itemFlags: jspb.Message.getFieldWithDefault(msg, 13, 0),
    stateFlags: jspb.Message.getFieldWithDefault(msg, 14, 0),
    roleId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    urlDomain: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.WebHistoryItem}
 */
proto.owpb.WebHistoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.WebHistoryItem;
  return proto.owpb.WebHistoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.WebHistoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.WebHistoryItem}
 */
proto.owpb.WebHistoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setItemFlags(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStateFlags(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.WebHistoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.WebHistoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.WebHistoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WebHistoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getItemFlags();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getStateFlags();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUrlDomain();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_id = 3;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_name = 4;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category_id = 5;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string category_name = 6;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string domain_name = 8;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string full_name = 9;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint64 timestamp = 10;
 * @return {number}
 */
proto.owpb.WebHistoryItem.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string url = 11;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string title = 12;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 item_flags = 13;
 * @return {number}
 */
proto.owpb.WebHistoryItem.prototype.getItemFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setItemFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 state_flags = 14;
 * @return {number}
 */
proto.owpb.WebHistoryItem.prototype.getStateFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setStateFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string role_id = 15;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string url_domain = 16;
 * @return {string}
 */
proto.owpb.WebHistoryItem.prototype.getUrlDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.WebHistoryItem} returns this
 */
proto.owpb.WebHistoryItem.prototype.setUrlDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DeleteAppHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DeleteAppHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DeleteAppHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteAppHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ltTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DeleteAppHistoryRequest}
 */
proto.owpb.DeleteAppHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DeleteAppHistoryRequest;
  return proto.owpb.DeleteAppHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DeleteAppHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DeleteAppHistoryRequest}
 */
proto.owpb.DeleteAppHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLtTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DeleteAppHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DeleteAppHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DeleteAppHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteAppHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLtTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 lt_timestamp = 1;
 * @return {number}
 */
proto.owpb.DeleteAppHistoryRequest.prototype.getLtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.DeleteAppHistoryRequest} returns this
 */
proto.owpb.DeleteAppHistoryRequest.prototype.setLtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DeleteAppHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DeleteAppHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DeleteAppHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteAppHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DeleteAppHistoryResponse}
 */
proto.owpb.DeleteAppHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DeleteAppHistoryResponse;
  return proto.owpb.DeleteAppHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DeleteAppHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DeleteAppHistoryResponse}
 */
proto.owpb.DeleteAppHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DeleteAppHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DeleteAppHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DeleteAppHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteAppHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletedCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 deleted_count = 1;
 * @return {number}
 */
proto.owpb.DeleteAppHistoryResponse.prototype.getDeletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.DeleteAppHistoryResponse} returns this
 */
proto.owpb.DeleteAppHistoryResponse.prototype.setDeletedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DeleteWebHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DeleteWebHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DeleteWebHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteWebHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ltTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DeleteWebHistoryRequest}
 */
proto.owpb.DeleteWebHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DeleteWebHistoryRequest;
  return proto.owpb.DeleteWebHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DeleteWebHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DeleteWebHistoryRequest}
 */
proto.owpb.DeleteWebHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLtTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DeleteWebHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DeleteWebHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DeleteWebHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteWebHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLtTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 lt_timestamp = 1;
 * @return {number}
 */
proto.owpb.DeleteWebHistoryRequest.prototype.getLtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.DeleteWebHistoryRequest} returns this
 */
proto.owpb.DeleteWebHistoryRequest.prototype.setLtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.DeleteWebHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.DeleteWebHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.DeleteWebHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteWebHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.DeleteWebHistoryResponse}
 */
proto.owpb.DeleteWebHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.DeleteWebHistoryResponse;
  return proto.owpb.DeleteWebHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.DeleteWebHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.DeleteWebHistoryResponse}
 */
proto.owpb.DeleteWebHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.DeleteWebHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.DeleteWebHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.DeleteWebHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.DeleteWebHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletedCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 deleted_count = 1;
 * @return {number}
 */
proto.owpb.DeleteWebHistoryResponse.prototype.getDeletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.DeleteWebHistoryResponse} returns this
 */
proto.owpb.DeleteWebHistoryResponse.prototype.setDeletedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.GetWebHistoryRequest.repeatedFields_ = [7,8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetWebHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetWebHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetWebHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    take: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reverseOrder: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    itemFlags: jspb.Message.getFieldWithDefault(msg, 6, 0),
    devicesIdList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.owpb.DomainAndUser.toObject, includeInstance),
    skip: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sortedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.owpb.FieldFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetWebHistoryRequest}
 */
proto.owpb.GetWebHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetWebHistoryRequest;
  return proto.owpb.GetWebHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetWebHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetWebHistoryRequest}
 */
proto.owpb.GetWebHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTake(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverseOrder(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setItemFlags(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicesId(value);
      break;
    case 8:
      var value = new proto.owpb.DomainAndUser;
      reader.readMessage(value,proto.owpb.DomainAndUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSkip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortedBy(value);
      break;
    case 11:
      var value = new proto.owpb.FieldFilter;
      reader.readMessage(value,proto.owpb.FieldFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetWebHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetWebHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetWebHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getReverseOrder();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getItemFlags();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDevicesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.owpb.DomainAndUser.serializeBinaryToWriter
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getSortedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.owpb.FieldFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 time_from = 1;
 * @return {number}
 */
proto.owpb.GetWebHistoryRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 time_to = 2;
 * @return {number}
 */
proto.owpb.GetWebHistoryRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string start_id = 3;
 * @return {string}
 */
proto.owpb.GetWebHistoryRequest.prototype.getStartId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setStartId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 take = 4;
 * @return {number}
 */
proto.owpb.GetWebHistoryRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool reverse_order = 5;
 * @return {boolean}
 */
proto.owpb.GetWebHistoryRequest.prototype.getReverseOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setReverseOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint32 item_flags = 6;
 * @return {number}
 */
proto.owpb.GetWebHistoryRequest.prototype.getItemFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setItemFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string devices_id = 7;
 * @return {!Array<string>}
 */
proto.owpb.GetWebHistoryRequest.prototype.getDevicesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setDevicesIdList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.addDevicesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.clearDevicesIdList = function() {
  return this.setDevicesIdList([]);
};


/**
 * repeated DomainAndUser users = 8;
 * @return {!Array<!proto.owpb.DomainAndUser>}
 */
proto.owpb.GetWebHistoryRequest.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.owpb.DomainAndUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.DomainAndUser, 8));
};


/**
 * @param {!Array<!proto.owpb.DomainAndUser>} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
*/
proto.owpb.GetWebHistoryRequest.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.owpb.DomainAndUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.DomainAndUser}
 */
proto.owpb.GetWebHistoryRequest.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.owpb.DomainAndUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional uint64 skip = 9;
 * @return {number}
 */
proto.owpb.GetWebHistoryRequest.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string sorted_by = 10;
 * @return {string}
 */
proto.owpb.GetWebHistoryRequest.prototype.getSortedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.setSortedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated FieldFilter filters = 11;
 * @return {!Array<!proto.owpb.FieldFilter>}
 */
proto.owpb.GetWebHistoryRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.owpb.FieldFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.FieldFilter, 11));
};


/**
 * @param {!Array<!proto.owpb.FieldFilter>} value
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
*/
proto.owpb.GetWebHistoryRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.owpb.FieldFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.FieldFilter}
 */
proto.owpb.GetWebHistoryRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.owpb.FieldFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.GetWebHistoryRequest} returns this
 */
proto.owpb.GetWebHistoryRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.owpb.WebHistoryCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.WebHistoryCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.WebHistoryCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.WebHistoryCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WebHistoryCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.owpb.WebHistoryItem.toObject, includeInstance),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.WebHistoryCollection}
 */
proto.owpb.WebHistoryCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.WebHistoryCollection;
  return proto.owpb.WebHistoryCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.WebHistoryCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.WebHistoryCollection}
 */
proto.owpb.WebHistoryCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.WebHistoryItem;
      reader.readMessage(value,proto.owpb.WebHistoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.WebHistoryCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.WebHistoryCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.WebHistoryCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.WebHistoryCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.owpb.WebHistoryItem.serializeBinaryToWriter
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated WebHistoryItem items = 1;
 * @return {!Array<!proto.owpb.WebHistoryItem>}
 */
proto.owpb.WebHistoryCollection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.owpb.WebHistoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.owpb.WebHistoryItem, 1));
};


/**
 * @param {!Array<!proto.owpb.WebHistoryItem>} value
 * @return {!proto.owpb.WebHistoryCollection} returns this
*/
proto.owpb.WebHistoryCollection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.owpb.WebHistoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.owpb.WebHistoryItem}
 */
proto.owpb.WebHistoryCollection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.owpb.WebHistoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.owpb.WebHistoryCollection} returns this
 */
proto.owpb.WebHistoryCollection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.owpb.WebHistoryCollection.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.WebHistoryCollection} returns this
 */
proto.owpb.WebHistoryCollection.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetWebHistoryOldestTimestampRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetWebHistoryOldestTimestampRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetWebHistoryOldestTimestampRequest}
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetWebHistoryOldestTimestampRequest;
  return proto.owpb.GetWebHistoryOldestTimestampRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetWebHistoryOldestTimestampRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetWebHistoryOldestTimestampRequest}
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetWebHistoryOldestTimestampRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetWebHistoryOldestTimestampRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetWebHistoryOldestTimestampRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.owpb.WebHistoryItemFlag = {
  UNKNOWN_WEB_HISTORY_ITEM_FLAG: 0,
  WEB_HISTORY_ITEM_BLOCKED: 2,
  WEB_HISTORY_ITEM_OPEN_IN_BROWSER: 4
};

/**
 * @enum {number}
 */
proto.owpb.AppFilterState = {
  APP_FILTER_STATE_NONE: 0,
  APP_ALLOW_MODE: 1,
  APP_BLOCK_MODE: 2,
  APP_ALLOW_IS_OUR_APP: 3,
  APP_ALLOW_IS_RUN_BY_OUR_APP: 4,
  APP_ALLOW_IS_SYSTEM_PROCESS: 5,
  APP_ALLOW_BY_SYSTEM_WHITE_LIST: 6,
  APP_ALLOW_BY_STRICT_DESKTOP: 7,
  APP_ALLOW_BY_WHITE_LIST: 8,
  APP_ALLOW_NOT_IN_BLACK_LIST: 9,
  APP_BLOCK_NOT_IN_WHITE_LIST: 10,
  APP_BLOCK_BY_BLACK_LIST: 11,
  APP_BLOCK_BY_STRICT_DESKTOP: 12
};

goog.object.extend(exports, proto.owpb);
